<template>
  <div>
    <v-dialog
      v-model="modal.profilecgroup"
      width="960px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark color="fourth" dense>
        <v-btn icon dark @click.stop="modal.profilecgroup = false">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>Company Group</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <profilecgroup
        v-if="modal.profilecgroup"
        :parent="modal.profilecgroup"
        @clicked="GetCGroup"
      ></profilecgroup>
    </v-dialog>
    <v-row>
      <v-col
        cols="12"
        :sm="cb_single === 'Y' ? 12 : 5"
        :md="cb_single === 'Y' ? 12 : 4"
        class="mt-n3"
      >
        <v-text-field
          v-if="cb_id_readonly === undefined"
          v-model="id[cb_type]"
          :ref="cb_id"
          :label="cb_single === 'Y' ? `${cb_title}` : ''"
          :id="cb_id"
          :name="cb_id"
          :prepend-inner-icon="cb_icon === undefined ? 'list' : false"
          background-color="combo"
          :placeholder="cb_single === 'Y' ? `${cb_title}` : ''"
          :required="cb_rules.length > 0 ? true : false"
          :rules="cb_rules.length > 0 && !id[cb_type] ? [cb_rules] : []"
          :readonly="cb_id_readonly === undefined ? false : cb_id_readonly"
          :value="cb_value_id"
          @keyup.enter="CBView"
          @change="CBView"
          v-bind="$attrs"
          @click:prepend-inner="CBShow"
        >
        </v-text-field>
        <v-text-field
          v-else
          v-model="id[cb_type]"
          :ref="cb_id"
          :label="cb_single === 'Y' ? `${cb_title}` : ''"
          :id="cb_id"
          :name="cb_id"
          prepend-inner-icon="list"
          background-color="readonly"
          :placeholder="cb_single === 'Y' ? `${cb_title}` : ''"
          :required="cb_rules.length > 0 ? true : false"
          :rules="cb_rules.length > 0 && !id[cb_type] ? [cb_rules] : []"
          :readonly="cb_id_readonly === undefined ? false : cb_id_readonly"
          :value="cb_value_id"
          v-bind="$attrs"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="7" md="8" v-if="cb_single !== 'Y'" class="mt-n3">
        <v-text-field
          v-model="desc[cb_type]"
          :ref="cb_desc"
          :label="cb_rules.length > 0 ? `${cb_title} *` : cb_title"
          :id="cb_desc"
          :name="cb_desc"
          maxlength="255"
          :background-color="cb_desc_readonly === 'readonly' ? 'readonly' : ''"
          :placeholder="
            cb_desc_placeholder === undefined ? ' ' : cb_desc_placeholder
          "
          :readonly="cb_desc_readonly === undefined ? true : cb_desc_readonly"
          :value="cb_value_desc"
          v-bind="$attrs"
        >
        </v-text-field>
      </v-col>
      <!--Modal-->
      <app-profile
        v-model="modal.profile"
        :id="detail.profile_id"
        :url_param="cb_url_parameter"
        action="add"
        @get="GetData"
      ></app-profile>
      <v-dialog
        v-model="modal['combobox']"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
        v-if="modal['combobox']"
      >
        <v-card>
          <v-toolbar dark color="primary" dense>
            <v-btn icon dark @click.stop="CBClose()">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-toolbar-title>{{ cb_title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="OpenProfile" v-if="cb_type === 'profile'">
              <v-icon>{{ $vars.V("icon_add") }}</v-icon>
            </v-btn>
            <v-btn
              icon
              dark
              @click="OpenProfileCGroup"
              v-else-if="cb_type === 'profilecgroup'"
            >
              <v-icon>{{ $vars.V("icon_add") }}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container grid-list-md class="pa-12">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="search[cb_type]"
                  id="search_combobox"
                  append-icon="search"
                  :label="`Search ${cb_title}`"
                  v-if="modal['combobox']"
                  @keyup.enter="CBSearch"
                  :loading="loading"
                  autocomplete="off"
                  autofocus
                >
                </v-text-field>
                <v-data-table
                  :headers="header[cb_type]"
                  :items="items[cb_type]"
                  dense
                  item-key="dataid"
                  @click:row="CBGet"
                  class="elevation-1 row-pointer"
                >
                  <template slot="headerCell" slot-scope="props">
                    <v-tooltip bottom>
                      <span slot="activator">
                        {{ props.header.text }}
                      </span>
                      <span>
                        {{ props.header.text }}
                      </span>
                    </v-tooltip>
                  </template>
                  <template slot="items" slot-scope="props">
                    <tr class="cursor">
                      <td class="text-xs-left">
                        {{ props.item[cb_items_id] }}
                      </td>
                      <td class="text-xs-left">
                        {{ props.item[cb_items_desc] }}
                      </td>
                    </tr>
                  </template>
                  <template
                    v-slot:item.action="{ item }"
                    v-if="cb_type === 'profile'"
                  >
                    <v-icon
                      small
                      class="mr-2"
                      @click="EditProfile(item.profile_id)"
                      title="edit"
                    >
                      mouse
                    </v-icon>
                  </template>
                  <template v-slot:no-data>
                    <small
                      >No Data Available
                      <a
                        href="javascript:;"
                        @click="OpenProfile"
                        v-if="cb_type === 'profile'"
                        >Add New?</a
                      ><a
                        href="javascript:;"
                        @click="OpenProfileCGroup"
                        v-else-if="cb_type === 'profilecgroup'"
                        >Add New?</a
                      ></small
                    ></template
                  >
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import profilecgroup from "@/views/modul/mod_profilecgroup/profilecgroup.vue";
export default {
  props: [
    "cb_type",
    "cb_url",
    "cb_url_parameter",
    "cb_title",
    "cb_id",
    "cb_desc",
    "cb_rules",
    "cb_id_readonly",
    "cb_desc_readonly",
    "cb_desc_placeholder",
    "cb_items_id",
    "cb_items_desc",
    "cb_items_additional_1",
    "cb_items_additional_2",
    "cb_items_additional_3",
    "cb_items_additional_4",
    "cb_items_additional_5",
    "cb_items_additional_6",
    "cb_items_additional_7",
    "cb_items_additional_8",
    "cb_single",
    "cb_value_id",
    "cb_value_desc",
    "cb_items_add",
    "cb_icon",
    "cb_push_add",
  ],
  watch: {
    cb_value_id: function (newdata) {
      if (newdata !== "") {
        this.id[this.cb_type] = newdata;
      } else {
        this.id[this.cb_type] = "";
      }
    },
    cb_value_desc: function (newdata) {
      if (newdata !== "") {
        this.desc[this.cb_type] = newdata;
      } else {
        this.desc[this.cb_type] = "";
      }
    },
    deep: true,
  },
  created() {
    this.id[this.cb_type] = this.cb_value_id;
    this.desc[this.cb_type] = this.cb_value_desc;
  },
  data: () => ({
    loading: false,
    header: {
      branch: [
        {
          text: "ID",
          value: "branch_id",
          align: "left",
        },
        { text: "Name", value: "branch_desc" },
      ],
      branchparent: [
        {
          text: "ID",
          value: "branchparent_id",
          align: "left",
        },
        { text: "Name", value: "branchparent_desc" },
      ],
      campaign: [
        {
          text: "ID",
          value: "campaign_id",
          align: "left",
        },
        { text: "Description", value: "campaign_desc" },
      ],
      natureclaim: [
        {
          text: "ID",
          value: "natureclaim_id",
          align: "left",
        },
        { text: "Description", value: "natureclaim_desc" },
      ],
      channel: [
        {
          text: "ID",
          value: "channel_id",
          align: "left",
        },
        { text: "Description", value: "channel_desc" },
      ],
      code: [
        {
          text: "ID",
          value: "code_id",
          align: "left",
        },
        { text: "Description", value: "code_desc" },
      ],
      currency: [
        {
          text: "ID",
          value: "currency_id",
          align: "left",
        },
        { text: "Description", value: "currency_desc" },
      ],
      costcenter: [
        {
          text: "ID",
          value: "costcenter_id",
          align: "left",
        },
        { text: "Description", value: "costcenter_desc" },
      ],
      department: [
        {
          text: "ID",
          value: "department_id",
          align: "left",
        },
        { text: "Description", value: "department_desc" },
      ],
      directorate: [
        {
          text: "ID",
          value: "directorate_id",
          align: "left",
        },
        { text: "Description", value: "directorate_desc" },
      ],
      division: [
        {
          text: "ID",
          value: "division_id",
          align: "left",
        },
        { text: "Description", value: "division_desc" },
      ],
      feeduty: [
        {
          text: "ID",
          value: "feeduty_id",
          align: "left",
        },
        { text: "Description", value: "feeduty_desc" },
        { text: "Amount", value: "feeduty_amount" },
      ],
      instype: [
        {
          text: "ID",
          value: "instype_id",
          align: "left",
        },
        { text: "Description", value: "instype_desc" },
      ],
      indemnity: [
        {
          text: "ID",
          value: "indemnity_id",
          align: "left",
        },
        { text: "Description", value: "indemnity_desc" },
      ],
      insured: [
        {
          text: "ID",
          value: "profile_id",
          align: "left",
        },
        { text: "Name", value: "profile_fullname" },
      ],
      lossitem: [
        {
          text: "ID",
          value: "lossitem_id",
          align: "left",
        },
        { text: "Description", value: "lossitem_desc" },
      ],
      leader: [
        {
          text: "ID",
          value: "users_id",
          align: "left",
        },
        { text: "Name", value: "users_name" },
      ],
      marketingofficer: [
        {
          text: "ID",
          value: "users_id",
          align: "left",
        },
        { text: "Name", value: "users_name" },
      ],
      menu: [
        {
          text: "ID",
          value: "menu_id",
          align: "left",
        },
        { text: "Name", value: "menu_desc" },
      ],
      payor: [
        {
          text: "ID",
          value: "profile_id",
          align: "left",
        },
        { text: "Name", value: "profile_name" },
      ],
      profile: [
        {
          text: "ID",
          value: "profile_id",
          align: "left",
        },
        { text: "Group", value: "profile_cgroup_desc" },
        { text: "Name", value: "profile_name" },
        { text: "LOB", value: "profile_lob_desc" },
        { text: "Action", value: "action" },
      ],
      policytype: [
        {
          text: "ID",
          value: "policytype_id",
          align: "left",
        },
        { text: "Name", value: "policytype_desc" },
      ],
      policyholder: [
        {
          text: "ID",
          value: "profile_id",
          align: "left",
        },
        { text: "Name", value: "profile_name" },
      ],
      userstype: [
        {
          text: "ID",
          value: "userstype_id",
          align: "left",
        },
        { text: "Name", value: "userstype_desc" },
      ],
      segment: [
        {
          text: "ID",
          value: "segment_id",
          align: "left",
        },
        { text: "Name", value: "segment_desc" },
      ],
      subclass: [
        {
          text: "ID",
          value: "subclass_id",
          align: "left",
        },
        { text: "Name", value: "subclass_desc" },
      ],
      gentab: [
        {
          text: "ID",
          value: "gentab_id",
          align: "left",
        },
        { text: "Description", value: "gentab_desc" },
      ],
      bank: [
        {
          text: "ID",
          value: "bank_id",
          align: "left",
        },
        { text: "Name", value: "bank_desc" },
      ],
      classsub: [
        {
          text: "ID",
          value: "classsub_id",
          align: "left",
        },
        { text: "Name", value: "classsub_desc" },
      ],
      class: [
        {
          text: "ID",
          value: "class_id",
          align: "left",
        },
        { text: "Description", value: "class_desc" },
      ],
      riskcov: [
        {
          text: "ID",
          value: "riskcov_id",
          align: "left",
        },
        { text: "Description", value: "riskcov_desc" },
      ],
      interest: [
        {
          text: "ID",
          value: "interest_id",
          align: "left",
        },
        { text: "Description", value: "interest_desc" },
      ],
      account: [
        {
          text: "ID",
          value: "account_id",
          align: "left",
        },
        { text: "Description", value: "account_desc" },
      ],

      receivedfrom: [
        {
          text: "ID",
          value: "profile_id",
          align: "left",
        },
        { text: "Description", value: "profile_name" },
      ],

      slcategory: [
        {
          text: "ID",
          value: "slcategory_id",
          align: "left",
        },
        { text: "Description", value: "slcategory_desc" },
      ],

      numbering: [
        {
          text: "ID",
          value: "numbering_id",
          align: "left",
        },
        { text: "Description", value: "numbering_desc" },
      ],
      deductible: [
        {
          text: "ID",
          value: "deductible_id",
          align: "left",
        },
        { text: "Description", value: "deductible_remarkseng" },
      ],
      users: [
        {
          text: "ID",
          value: "users_id",
          align: "left",
        },
        { text: "Name", value: "users_name" },
      ],
      vouchertype: [
        {
          text: "ID",
          value: "vouchertype_id",
          align: "left",
        },
        { text: "Description", value: "vouchertype_desc" },
      ],

      companyaccount: [
        {
          text: "ID",
          value: "compacc_id",
          align: "left",
        },
        { text: "Description", value: "compacc_desc" },
      ],
      grade: [
        {
          text: "ID",
          value: "grade_id",
          align: "left",
        },
        { text: "Desc", value: "grade_desc" },
      ],
      classtc: [
        {
          text: "ID",
          value: "classtc_id",
          align: "left",
        },
        { text: "Class ID", value: "classtc_classid" },
      ],
      letter: [
        {
          text: "ID",
          value: "letter_id",
          align: "left",
        },
        { text: "Type", value: "letter_type" },
      ],
      causeloss: [
        {
          text: "ID",
          value: "causeloss_id",
          align: "left",
        },
        { text: "Desc", value: "causeloss_desc" },
      ],
      country: [
        {
          text: "ID",
          value: "country_id",
          align: "left",
        },
        { text: "Desc", value: "country_desc" },
      ],
      profiletype: [
        {
          text: "ID",
          value: "profiletype_id",
          align: "left",
        },
        { text: "Desc", value: "profiletype_desc" },
      ],
      profilecgroup: [
        {
          text: "ID",
          value: "profilecgroup_id",
          align: "left",
        },
        { text: "Desc", value: "profilecgroup_desc" },
      ],
      profilelob: [
        {
          text: "ID",
          value: "profilelob_id",
          align: "left",
        },
        { text: "Desc", value: "profilelob_desc" },
      ],
      province: [
        {
          text: "ID",
          value: "gendet_id",
          align: "left",
        },
        { text: "Desc", value: "gendet_desc" },
      ],
      regency: [
        {
          text: "ID",
          value: "gendet_id",
          align: "left",
        },
        { text: "Desc", value: "gendet_desc" },
      ],
      districts: [
        {
          text: "ID",
          value: "gendet_id",
          align: "left",
        },
        { text: "Desc", value: "gendet_desc" },
      ],
      villages: [
        {
          text: "ID",
          value: "gendet_id",
          align: "left",
        },
        { text: "Desc", value: "gendet_desc" },
      ],
      doccheck: [
        {
          text: "ID",
          value: "doccheck_id",
          align: "left",
        },
        { text: "Kategori", value: "doccheck_category" },
        { text: "Sub Kategori", value: "doccheck_subcategory" },
        { text: "Dokumen", value: "doccheck_name" },
      ],
      profileaccount: [
        {
          text: "ID",
          value: "profileaccount_id",
          align: "left",
        },
        { text: "CCY", value: "profileaccount_currency" },
        { text: "Bank", value: "profileaccount_bank" },
        { text: "Bank Name", value: "profileaccount_bankcompanyname" },
        { text: "Bank Branch", value: "profileaccount_bankbranch" },
        { text: "Account No", value: "profileaccount_accountno" },
        { text: "Account Name", value: "profileaccount_accountname" },
      ],
    },
    id: {},
    desc: {},
    additional_1: {},
    additional_2: {},
    additional_3: {},
    additional_4: {},
    additional_5: {},
    additional_6: {},
    additional_7: {},
    additional_8: {},
    modal: {
      combobox: false,
      profile: false,
      profilecgroup: false,
    },
    detail: {
      profile_id: "",
    },
    items: [],
    search: {},
    modalflag: "",
  }),
  methods: {
    CBShow() {
      this.modalflag = "show";
      this.modal["combobox"] = true;
      this.search[this.cb_type] = this.id[this.cb_type];
      this.CBCall(this.id[this.cb_type], "show");
      if (this.cb_type === "profile" && this.cb_push_add === "Y") {
        this.OpenProfile();
      }
    },
    // View Data and show dialog when Enter
    CBView() {
      this.modalflag = "list";
      this.modal["combobox"] = true;
      this.CBCall(this.id[this.cb_type], "list");
      if (this.cb_type === "profile" && this.cb_push_add === "Y") {
        this.OpenProfile();
      }
    },
    //Close Dialog
    CBClose() {
      if (this.modalflag !== "show") {
        this.id[this.cb_type] = "";
        this.desc[this.cb_type] = "";
      }
      this.modal["combobox"] = false;
      this.$emit("clicked", "||" + this.cb_items_add);
      var v = this;
      setTimeout(function () {
        document.getElementById(v.cb_id).focus();
      }, 500);
    },
    // Search data
    CBSearch() {
      this.modalflag = "search";
      this.loading = true;
      this.CBCall(this.search[this.cb_type], "search");
    },
    CBGet(value) {
      this.id[this.cb_type] = value[this.cb_items_id];
      this.desc[this.cb_type] = value[this.cb_items_desc];
      this.additional_1[this.cb_type] = value[this.cb_items_additional_1];
      this.additional_2[this.cb_type] = value[this.cb_items_additional_2];
      this.additional_3[this.cb_type] = value[this.cb_items_additional_3];
      this.additional_4[this.cb_type] = value[this.cb_items_additional_4];
      this.additional_5[this.cb_type] = value[this.cb_items_additional_5];
      this.additional_6[this.cb_type] = value[this.cb_items_additional_6];
      this.additional_7[this.cb_type] = value[this.cb_items_additional_7];
      this.additional_8[this.cb_type] = value[this.cb_items_additional_8];
      this.modal["combobox"] = false;
      this.$emit(
        "clicked",
        value[this.cb_items_id] +
          "|" +
          value[this.cb_items_desc] +
          "|" +
          this.cb_items_add +
          "|" +
          value[this.cb_items_additional_1] +
          "|" +
          value[this.cb_items_additional_2] +
          "|" +
          value[this.cb_items_additional_3] +
          "|" +
          value[this.cb_items_additional_4] +
          "|" +
          value[this.cb_items_additional_5] +
          "|" +
          value[this.cb_items_additional_6] +
          "|" +
          value[this.cb_items_additional_7] +
          "|" +
          value[this.cb_items_additional_8]
      );
    },
    CBCall(values, flag) {
      this.loading = true;
      let formdata = {
        datavalue: values,
      };
      let parameter = this.cb_url_parameter;
      if (parameter !== undefined || parameter !== "") {
        let newparameter = JSON.parse(
          '{"' +
            decodeURI(parameter)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
        formdata = Object.assign(formdata, newparameter);
      }
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST(this.cb_url), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length === 0) {
            this.id[this.cb_type] = "";
            this.desc[this.cb_type] = "";
            this.items[this.cb_type] = [];
          } else if (feedback.length === 1 && flag !== "show") {
            if (feedback[0].feedback === "Y") {
              this.modal["combobox"] = false;
              this.id[this.cb_type] = feedback[0][this.cb_items_id];
              this.desc[this.cb_type] = feedback[0][this.cb_items_desc];
              this.$emit(
                "clicked",
                feedback[0][this.cb_items_id] +
                  "|" +
                  feedback[0][this.cb_items_desc] +
                  "|" +
                  this.cb_items_add +
                  "|" +
                  feedback[0][this.cb_items_additional_1] +
                  "|" +
                  feedback[0][this.cb_items_additional_2] +
                  "|" +
                  feedback[0][this.cb_items_additional_3] +
                  "|" +
                  feedback[0][this.cb_items_additional_4] +
                  "|" +
                  feedback[0][this.cb_items_additional_5] +
                  "|" +
                  feedback[0][this.cb_items_additional_6] +
                  "|" +
                  feedback[0][this.cb_items_additional_7] +
                  "|" +
                  feedback[0][this.cb_items_additional_8]
              );
              var v = this;
              setTimeout(function () {
                document.getElementById(v.cb_id).focus();
              }, 500);
            } else {
              alert(feedback[0].feedback);
            }
          } else {
            if (flag === "list") {
              this.modal["combobox"] = true;
              this.search[this.cb_type] = this.id[this.cb_type];
            }
            this.items[this.cb_type] = feedback;
          }
          this.loading = false;
        })
        .catch((e) => {
          alert(e);
          this.loading.list = false;
        });
    },
    GetData(value) {
      this.search[this.cb_type] = value;
      this.CBSearch();
    },
    OpenProfile() {
      this.modal.profile = true;
    },
    EditProfile(id) {
      this.modal.profile = true;
      this.detail.profile_id = id;
    },
    OpenProfileCGroup() {
      this.modal.profilecgroup = true;
    },
    GetCGroup(value) {
      this.modal.profilecgroup = false;
      this.search[this.cb_type] = value;
      this.CBSearch();
    },
  },
  components: {
    profilecgroup,
  },
};
</script>
