<template>
  <div>
    <!-- Alert remains same -->
    <v-alert border="left" color="secondary" dark dense icon="analytics">
      Scoring
    </v-alert>

    <!-- Card content same as before -->
    <v-card elevation="5" class="mx-1 my-5">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="score">Score: {{ calculateTotalScore }}</div>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="white--text"
          @click="Confirm('write', '')"
          small
        >
          <v-icon left dark>mdi-content-save</v-icon>Save
        </v-btn>
      </v-card-title>

      <v-row class="mx-2">
        <v-col
          cols="12"
          sm="4"
          v-for="(section, sectionIndex) in scoring"
          :key="section.subtype"
        >
          <div class="subtitle-1 font-weight-medium mb-2">
            {{ sectionIndex + 1 }}. {{ section.subtype }}
          </div>
          <div
            v-for="item in section.items"
            :key="item.ticketscoring_row"
            class="custom-radio mb-2"
            :class="{
              selected:
                selectedOptions[sectionIndex] === item.ticketscoring_title,
            }"
            @click="
              HandleSelect(
                sectionIndex,
                item.ticketscoring_row,
                item.ticketscoring_score,
                item.ticketscoring_title
              )
            "
          >
            <div class="d-flex align-center justify-space-between py-3 px-4">
              <span>{{ item.ticketscoring_title }}</span>
              <v-icon
                v-if="
                  selectedOptions[sectionIndex] === item.ticketscoring_title
                "
                color="white"
                >mdi-check</v-icon
              >
              <div v-else class="checkbox-placeholder"></div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="12" class="mt-n3">
          <label class="caption"
            >{{ $vars.V("txt_acceptance_remark") }} *</label
          >
          <v-textarea outlined v-model="notes"></v-textarea>
        </v-col>
      </v-row>
    </v-card>

    <!-- Snackbar remains same -->
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Write"
            >{{ confirm.text }}</v-btn
          >
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["ticketid", "type"],

  data: () => ({
    scoring: [],
    selectedOptions: {},
    datascoring: [],
    notes: "",
    loading: false,
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
  }),

  computed: {
    calculateTotalScore() {
      return Object.values(this.datascoring).reduce((total, item) => {
        return total + (parseInt(item.ticketscoring_point) || 0);
      }, 0);
    },
  },

  created() {
    this.ListScoring();
  },

  methods: {
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    ListScoring() {
      const formdata = {
        ticketscoring_ticketid: this.ticketid,
        ticketscoring_type: this.type,
        order_by: "ticketscoring_score",
        order_type: "ASC",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicketScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              const groupedData = this.GroupScoringBySubtype(response.data);
              this.scoring = groupedData;
              this.notes = feedback[0].ticketscoring_notes;
              this.InitializeSelectedOptions();
            }
          }
        });
    },
    GroupScoringBySubtype(data) {
      const grouped = {};
      data.forEach((item) => {
        if (!grouped[item.ticketscoring_subtype]) {
          grouped[item.ticketscoring_subtype] = [];
        }
        grouped[item.ticketscoring_subtype].push(item);
      });

      return Object.entries(grouped).map(([subtype, items]) => ({
        subtype,
        items: items.sort(
          (a, b) =>
            parseInt(a.ticketscoring_score) - parseInt(b.ticketscoring_score)
        ),
      }));
    },
    HandleSelect(index, row, score, title) {
      this.$set(this.selectedOptions, index, title);
      this.$set(this.datascoring, index, {
        ticketscoring_row: row,
        ticketscoring_point: score,
      });
    },
    Confirm(flag) {
      if (flag === "write") {
        this.confirm.dialog = true;
        this.confirm.title = "Save";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    Write() {
      this.confirm.text = "Loading...";
      let multiData = Object.values(this.datascoring).map((data) => ({
        ticketscoring_row: data.ticketscoring_row,
        ticketscoring_point: String(data.ticketscoring_point),
      }));
      let formdata = {
        url_type: "edit",
        ticketscoring_ticketid: this.ticketid,
        ticketscoring_type: this.type,
        ticketscoring_notes: this.notes,
        multidata_update: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteTicketScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.SnackBar(true, "success", "Success Update Scoring", 2000);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
        });
    },
    InitializeSelectedOptions() {
      this.scoring.forEach((section, index) => {
        const selectedItem = section.items.find(
          (item) => item.ticketscoring_score === item.ticketscoring_point
        );
        if (selectedItem) {
          this.$set(
            this.selectedOptions,
            index,
            selectedItem.ticketscoring_title
          );
          this.$set(this.datascoring, index, {
            ticketscoring_row: selectedItem.ticketscoring_row,
            ticketscoring_point: selectedItem.ticketscoring_point,
          });
        }
      });
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
  },
};
</script>

<style scoped>
/* Styles remain the same */
.custom-radio {
  background-color: #fff3e0;
  border-radius: 4px;
  transition: all 0.2s ease;
  cursor: pointer;
  border: 1px solid transparent;
}

.custom-radio:hover {
  background-color: #ffe0b2;
}

.custom-radio.selected {
  background-color: #00b500;
  color: #ffffff;
}

.checkbox-placeholder {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}

.score {
  border: 2px solid;
  border-radius: 9px;
  padding: 2px 20px;
  font-size: 18px;
  font-weight: bold;
}
</style>
