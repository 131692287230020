<template>
  <div>
    <!-- Alert remains same -->
    <v-alert border="left" color="secondary" dark dense icon="analytics">
      Scoring
    </v-alert>

    <!-- Card content same as before -->
    <v-card elevation="5" class="mx-1 my-5">
      <v-card-title class="d-flex justify-space-between align-center">
        <v-spacer></v-spacer>
        <div class="score">Score: {{ calculateTotalScore }}</div>
      </v-card-title>

      <v-row class="mx-2">
        <v-col
          cols="12"
          sm="4"
          v-for="(section, sectionIndex) in scoring"
          :key="section.subtype"
        >
          <div class="subtitle-1 font-weight-medium mb-2">
            {{ sectionIndex + 1 }}. {{ section.subtype }}
          </div>
          <div
            v-for="item in section.items"
            :key="item.ticketscoring_row"
            class="custom-radio mb-2"
            :class="{
              selected:
                selectedOptions[sectionIndex] === item.ticketscoring_title,
            }"
          >
            <div class="d-flex align-center justify-space-between py-3 px-4">
              <span>{{ item.ticketscoring_title }}</span>
              <v-icon
                v-if="
                  selectedOptions[sectionIndex] === item.ticketscoring_title
                "
                color="white"
                >mdi-check</v-icon
              >
              <div v-else class="checkbox-placeholder"></div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="12" class="mt-n3">
          <label class="caption"
            >{{ $vars.V("txt_acceptance_remark") }} *</label
          >
          <v-textarea outlined v-model="notes" readonly></v-textarea>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["ticketid", "type"],

  data: () => ({
    scoring: [],
    selectedOptions: {},
    datascoring: [],
    notes: "",
    loading: false,
  }),

  computed: {
    calculateTotalScore() {
      return Object.values(this.datascoring).reduce((total, item) => {
        return total + (parseInt(item.ticketscoring_point) || 0);
      }, 0);
    },
  },

  created() {
    this.ListScoring();
  },

  methods: {
    ListScoring() {
      const formdata = {
        ticketscoring_ticketid: this.ticketid,
        ticketscoring_type: this.type,
        order_by: "ticketscoring_score",
        order_type: "ASC",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicketScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              const groupedData = this.GroupScoringBySubtype(response.data);
              this.scoring = groupedData;
              this.notes = feedback[0].ticketscoring_notes;
              this.InitializeSelectedOptions();
            }
          }
        });
    },
    GroupScoringBySubtype(data) {
      const grouped = {};
      data.forEach((item) => {
        if (!grouped[item.ticketscoring_subtype]) {
          grouped[item.ticketscoring_subtype] = [];
        }
        grouped[item.ticketscoring_subtype].push(item);
      });

      return Object.entries(grouped).map(([subtype, items]) => ({
        subtype,
        items: items.sort(
          (a, b) =>
            parseInt(a.ticketscoring_score) - parseInt(b.ticketscoring_score)
        ),
      }));
    },

    InitializeSelectedOptions() {
      this.scoring.forEach((section, index) => {
        const selectedItem = section.items.find(
          (item) => item.ticketscoring_score === item.ticketscoring_point
        );
        if (selectedItem) {
          this.$set(
            this.selectedOptions,
            index,
            selectedItem.ticketscoring_title
          );
          this.$set(this.datascoring, index, {
            ticketscoring_row: selectedItem.ticketscoring_row,
            ticketscoring_point: selectedItem.ticketscoring_point,
          });
        }
      });
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
  },
};
</script>

<style scoped>
/* Styles remain the same */
.custom-radio {
  background-color: #fff3e0;
  border-radius: 4px;
  transition: all 0.2s ease;
  cursor: pointer;
  border: 1px solid transparent;
}

.custom-radio:hover {
  background-color: #ffe0b2;
}

.custom-radio.selected {
  background-color: #00b500;
  color: #ffffff;
}

.checkbox-placeholder {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}

.score {
  border: 2px solid;
  border-radius: 9px;
  padding: 2px 20px;
  font-size: 18px;
  font-weight: bold;
}
</style>
