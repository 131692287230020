<template>
  <div id="dashboard">
    <app-dashboard-insurance
      v-if="currentRoute === 'dashboardinsurance'"
    ></app-dashboard-insurance>
    <app-dashboard-bank
      v-if="currentRoute === 'dashboardbank'"
    ></app-dashboard-bank>
    <app-dashboard-leader
      v-if="currentRoute === 'dashboardleader'"
    ></app-dashboard-leader>
    <app-dashboard-agency
      v-if="currentRoute === 'dashboardagency'"
    ></app-dashboard-agency>
  </div>
</template>
<script>
// import VueApexCharts from "vue-apexcharts";
export default {
  name: "Dashboard",
  // components: {
  //   apexchart: VueApexCharts,
  // },
  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "dashboard",
      },
      {
        text: "Quotations",
        disabled: false,
        href: "#",
      },
    ],
    form: {
      users_avatar_url: "",
      users_name: "",
      users_typedesc: "",
      users_email: "",
      users_phone_1: "",
      users_type: "",
    },
  }),

  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {},
};
</script>
