<template>
  <div>
    <app-drawer_menu drawer_menu_title="Dashboard"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-row class="justify-center align-cente my-10">
      <v-col cols="12" sm="6" md="3">
        <v-card
          class="pa-4 rounded-lg h-100 bg-gradient-prod hover-card"
          elevation="2"
          @click="GetData('O')"
        >
          <v-card-text class="pa-0">
            <div
              class="d-flex flex-wrap align-items-center justify-space-between gap-1 mb-4"
            >
              <div class="d-flex align-items-center gap-2">
                <!-- Icon Circle -->
                <v-avatar color="#b3ceb37d" size="42">
                  <v-icon color="green" size="28">mdi-check-decagram</v-icon>
                </v-avatar>
                <div>
                  <h2 style="color: #111827" class="font-weight-bold">
                    {{ "IDR " + total.prod_amount }}
                  </h2>
                  <span
                    class="text-secondary-light text-body-2 font-weight-medium"
                  >
                    Produksi
                  </span>
                </div>
              </div>
            </div>
            <div class="text-body-2">
              <span color="#f443365c">{{ total.produksi }}</span> Data Produksi
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card
          class="pa-4 rounded-lg h-100 bg-gradient-request hover-card"
          elevation="2"
          @click="GetData('R')"
        >
          <v-card-text class="pa-0">
            <div
              class="d-flex flex-wrap align-items-center justify-space-between gap-1 mb-4"
            >
              <div class="d-flex align-items-center gap-2">
                <!-- Icon Circle -->
                <v-avatar size="42" color="#ff980030" class="flex-shrink-0">
                  <v-icon size="28" color="orange"
                    >mdi-book-arrow-left-outline</v-icon
                  >
                </v-avatar>
                <div>
                  <h2 style="color: #111827" class="font-weight-bold">
                    {{ "IDR " + total.req_amount }}
                  </h2>
                  <span
                    class="text-secondary-light text-body-2 font-weight-medium"
                  >
                    Request
                  </span>
                </div>
              </div>
            </div>
            <div class="text-body-2">
              <span color="#f443365c">{{ total.request }}</span> Data Request
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Reject Card -->
      <v-col cols="12" sm="6" md="3">
        <v-card
          class="pa-4 rounded-lg h-100 bg-gradient-reject hover-card"
          elevation="2"
          @click="GetData('J')"
        >
          <v-card-text class="pa-0">
            <div
              class="d-flex flex-wrap align-items-center justify-space-between gap-1 mb-4"
            >
              <div class="d-flex align-items-center gap-2">
                <!-- Icon Circle -->
                <v-avatar size="42" color="#f443365c" class="flex-shrink-0">
                  <v-icon color="red"> mdi-close-circle </v-icon>
                </v-avatar>
                <div>
                  <h2 style="color: #111827" class="font-weight-bold">
                    {{ "IDR " + total.reject_amount }}
                  </h2>
                  <span
                    class="text-secondary-light text-body-2 font-weight-medium"
                  >
                    Reject
                  </span>
                </div>
              </div>
            </div>
            <div class="text-body-2">
              <span color="#f443365c">{{ total.reject }}</span> Data Reject
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="header"
          :items="items"
          :loading="loading.quotation"
          loading-text="Loading... Please wait"
          dense
          item-key="dataid"
          class="elevation-1 row-pointer"
        >
          <template slot="headerCell" slot-scope="props">
            <v-tooltip bottom>
              <span slot="activator">
                {{ props.header.text }}
              </span>
              <span>
                {{ props.header.text }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:no-data> No Data Available </template>
          <template v-slot:item.ticket_picgender="{ item }">
            {{ item.ticket_picgender }}
          </template>
          <template v-slot:item.ticket_id="{ item }">
            {{ item.ticket_id }}<br />
            <small
              :class="
                item.ticket_quotype === 'U'
                  ? 'primary--text'
                  : 'secondary--text'
              "
              ><b>Penawaran {{ item.ticket_quotypedesc }}</b></small
            ><br />
            <v-chip
              :class="item.ticket_key === 'L' ? 'red--text' : 'green--text'"
              outlined
              x-small
              ><b>{{ item.ticket_keydesc }}</b></v-chip
            >
          </template>
          <template v-slot:item.action="{ item }">
            <div @click="CBGet(item.ticket_id)" class="cursor primary--text">
              <small><u>Edit</u></small>
            </div>
            <div @click="CBTodo(item.ticket_id)" class="cursor secondary--text">
              <small
                ><u>Follow Up ({{ item.ticket_todocount }})</u></small
              >
            </div>
            <div
              @click="CBPrint(item.ticket_id, item.ticket_quotype)"
              class="cursor primary--text"
            >
              <small><u>Print</u></small>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import VueApexCharts from "vue-apexcharts";
export default {
  name: "DashboardAgency",
  // components: {
  //   apexchart: VueApexCharts,
  // },
  data: () => ({
    loading: {
      list: false,
      quotation: false,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    total: {
      produksi: 0,
      prod_amount: 0,
      request: 0,
      req_amount: 0,
      reject: 0,
      reject_amount: 0,
    },
    selection: 0,
    images_welcome: "../../welcome.png",
    form: {
      users_avatar_url: "",
      users_name: "",
      users_id: "",
      users_type: "",
      users_typedesc: "",
      users_otherid: "",
    },

    items: [],
    limit: "1000",
    header: [
      { text: "Actions", value: "action", sortable: false, width: "100" },
      {
        text: "ID",
        value: "ticket_id",
        align: "left",
      },
      { text: "Sub Class", value: "ticket_subclassdesc" },
      { text: "Principal Name", value: "ticket_name" },
      { text: "Principal Address", value: "ticket_address" },
      { text: "PIC Gender", value: "ticket_picgender" },
      { text: "PIC Name", value: "ticket_picname" },
      { text: "PIC Title", value: "ticket_pictitle" },
      { text: "PIC Mobile", value: "ticket_mobile" },
      { text: "PIC Email", value: "ticket_email" },
    ],
  }),

  created() {
    this.FirstLoad();
  },
  methods: {
    FirstLoad() {
      this.UsersDetail();
      this.GetTotal();
    },
    UsersDetail() {
      let users_detail = JSON.parse(
        localStorage.getItem("local_core_users_detail")
      );
      this.form.users_avatar_url = users_detail[0].users_avatar_url;
      this.form.users_id = users_detail[0].users_id;
      this.form.users_type = users_detail[0].users_type;
      this.form.users_name = users_detail[0].users_name;
      this.form.users_typedesc = users_detail[0].users_typedesc;
      this.form.users_email = users_detail[0].users_email;
      this.form.users_phone_1 = users_detail[0].users_phone_1;
      this.form.users_otherid = users_detail[0].users_otherid;
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    CBGet(value) {
      this.$router
        .push({
          name: "quotationdetail",
          params: { id: this.$functions.EncodeUrl(value) },
        })
        .catch(() => {});
    },
    GetTotal() {
      let formdata = {
        usersid: this.form.users_otherid,
        userstype: this.form.users_type,
        sdate: "2024-01-01",
        edate: "2024-12-30",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListDashboard"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              feedback.forEach((item) => {
                switch (item.dashboard_status) {
                  case "O":
                    this.total.produksi = item.dashboard_total;
                    this.total.prod_amount = this.$functions.NewFormatNumber(
                      item.dashboard_amount
                    );
                    break;
                  case "R":
                    this.total.request = item.dashboard_total;
                    this.total.req_amount = this.$functions.NewFormatNumber(
                      item.dashboard_amount
                    );
                    break;
                  case "J":
                    this.total.reject = item.dashboard_total;
                    this.total.reject_amount = this.$functions.NewFormatNumber(
                      item.dashboard_amount
                    );
                    break;
                }
              });
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.SnackBar(true, "error", "No Data Available", 0);
          }
        });
    },
    GetData(key) {
      this.loading.quotation = true;
      let formdata = {
        ticket_mo: this.form.users_otherid,
        ticket_key: key,
        order_by: "ticket_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListTicket"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.loading.quotation = false;
              this.items = feedback;
            } else {
              this.items = feedback;
              this.loading.quotation = false;
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.items = feedback;
            this.loading.quotation = false;
            this.SnackBar(true, "error", "No Data Available", 0);
          }
        });
    },
  },
};
</script>

<style scoped>
.hover-card:hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: scale(1.03);
  box-shadow: 1px 3px 4px rgb(255 136 0) !important;
}
.bg-success-100 {
  background-color: rgba(40, 199, 111, 0.1) !important;
}

.text-success-600 {
  color: #28c76f !important;
}

.text-secondary-light {
  color: #6e6b7b !important;
}

.bg-gradient-reject {
  background: linear-gradient(to right, #ffffff, #ffecec);
}

.bg-gradient-request {
  background: linear-gradient(to right, #ffffff, #ffeacd);
}
.bg-gradient-prod {
  background: linear-gradient(to right, #ffffff, #00aff25e);
}

.mb-8 {
  margin-bottom: 2rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.75rem;
}
</style>
