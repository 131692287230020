<template>
  <div>
    <app-drawer_menu
      drawer_menu_title="Sub Class Of Business"
    ></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs
      background-color="white"
      color="fourth"
      v-if="this.access.read === 1"
    >
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad"> Form </v-tab>
      <v-tab href="#tab-list" @click="List"> List </v-tab>
      <v-tab-item value="tab-form">
        <v-form
          enctype="multipart/form-data"
          ref="form_subclass"
          lazy-validation
        >
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{ $vars.V("icon_add") }}</v-icon
                >{{ $vars.V("txt_add") }}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{ $vars.V("icon_save") }}</v-icon
                >{{ $vars.V("txt_save") }}
              </v-btn>
            </v-col>
            <!-- ID -->
            <v-col cols="12" sm="12" md="3">
              <app-cb
                cb_type="class"
                cb_url="apiListClass"
                cb_url_parameter="class_actived=Y"
                cb_title="Class"
                cb_id="subclass_classid"
                cb_desc="subclass_classdesc"
                cb_rules="Please fill Main Class"
                cb_desc_readonly="readonly"
                cb_items_id="class_id"
                cb_items_desc="class_desc"
                :cb_value_id="form.subclass_classid"
                :cb_value_desc="form.subclass_classdesc"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="3" md="2" class="mt-n3">
              <v-text-field
                v-model="form.subclass_id"
                required
                :rules="form.subclass_id_rules"
                :readonly="form.subclass_id_readonly"
                :background-color="form.subclass_id_readonly ? 'readonly' : ''"
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID *"
                placeholder="ID"
                id="subclass_id"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Desc -->
            <v-col cols="12" sm="5" md="3" class="mt-n3">
              <v-text-field
                v-model="form.subclass_desc"
                required
                :rules="form.subclass_desc_rules"
                :maxlength="maxinput.desc"
                label="Description *"
                placeholder="Description"
                id="subclass_desc"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Ujroh -->
            <v-col cols="12" sm="2" md="1" class="mt-n3">
              <v-text-field
                v-model.lazy="form.subclass_ujroh"
                ref="subclass_ujroh"
                label="Ujroh"
                placeholder="0"
                background-color="numeric"
                v-money="format_subclass.ujroh"
                id="subclass_ujroh"
                reverse
              >
              </v-text-field>
            </v-col>
            <!-- Max Out Go -->
            <v-col cols="12" sm="2" md="1" class="mt-n3">
              <v-text-field
                v-model.lazy="form.subclass_maxoutgo"
                ref="subclass_maxoutgo"
                label="Max Out Go"
                placeholder="0"
                background-color="numeric"
                v-money="format_subclass.maxoutgo"
                id="subclass_maxoutgo"
                reverse
              >
              </v-text-field>
            </v-col>
            <!-- Active -->
            <v-col cols="12" sm="2" md="2" class="mt-n6">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.subclass_actived"
                color="primary"
                id="form.subclass_actived"
                :label="form.subclass_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>

            <span v-show="false">
              <!-- Passenger F -->
              <v-col cols="12" sm="2" md="2" class="mt-n6">
                <small class="caption">Passenger</small><br />
                <v-btn-toggle dense v-model="form.subclass_passengerf" rounded>
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <!-- Pml F -->
              <v-col cols="12" sm="2" md="2" class="mt-n6">
                <small class="caption">Pml</small><br />
                <v-btn-toggle dense v-model="form.subclass_pmlf" rounded>
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <!-- Ojk Rate F -->
              <v-col cols="12" sm="2" md="2" class="mt-n6">
                <small class="caption">Ojk Rate</small><br />
                <v-btn-toggle dense v-model="form.subclass_ojkratef" rounded>
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <!-- Voyage F -->
              <v-col cols="12" sm="2" md="2" class="mt-n6">
                <small class="caption">Voyage</small><br />
                <v-btn-toggle dense v-model="form.subclass_voyagef" rounded>
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <!-- Indemnity F -->
              <v-col cols="12" sm="2" md="2" class="mt-n6">
                <small class="caption">Indemnity</small><br />
                <v-btn-toggle dense v-model="form.subclass_indemnityf" rounded>
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>

              <!-- Conjunction F -->
              <v-col cols="12" sm="2" md="2" class="mt-n6">
                <small class="caption">Conjunction</small><br />
                <v-btn-toggle
                  dense
                  v-model="form.subclass_conjunctionf"
                  rounded
                >
                  <v-btn small> No </v-btn>
                  <v-btn small> Yes </v-btn>
                </v-btn-toggle>
              </v-col>
            </span>
            <v-col cols="12" sm="12" md="12">
              <v-btn-toggle v-if="url_type !== 'add'">
                <v-btn small @click="Dialog('OI')">Object Info</v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                <v-banner
                  v-model="cont"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created Date 	 : {{form.subclass_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.subclass_cuser)">{{form.subclass_cuser}}</span>
Last Update Date : {{form.subclass_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.subclass_luser)">{{form.subclass_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-2" v-show="url_type !== 'add' ? true : false">
            <v-tabs
              background-color="white"
              color="fourth"
              v-if="access.read === 1"
            >
              <v-tab
                id="tabscoring"
                href="#tab-scoring"
                @click="ListScoring(form.subclass_id)"
              >
                Scoring
              </v-tab>
              <v-tab
                id="tabsdoccheck"
                href="#tab-doccheck"
                @click="ListDocCheck(form.subclass_id)"
              >
                Dokumen Cek
              </v-tab>
              <v-tab-item value="tab-scoring">
                <template>
                  <v-card-title class="ml-n4">
                    <v-btn
                      :color="
                        selected_scoring.length === items_scoring.length
                          ? 'fourth'
                          : ''
                      "
                      @click="SelectAllPageScoring"
                      class="mr-2"
                      title="select all page"
                      small
                      >{{ $vars.V("txt_select_all_page") }}
                    </v-btn>
                    <v-btn
                      color="error"
                      title="delete selected"
                      @click="ConfirmScoring('multidelete', '')"
                      class="mr-2"
                      v-if="selected_scoring.length > 0"
                      :disabled="access.delete === 0 ? true : false"
                      small
                    >
                      <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
                    </v-btn>
                    <v-btn
                      @click="AddNewScoring"
                      class="mr-2"
                      title="Add New"
                      small
                      >Add
                    </v-btn>
                    <v-btn
                      color="primary"
                      title="search"
                      v-if="btn_search_scoring === false"
                      @click="btn_search_scoring = true"
                      small
                    >
                      <v-icon>{{ $vars.V("icon_search") }}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search_scoring"
                      append-icon="search"
                      @click:append="btn_search_scoring = false"
                      label="Search"
                      single-line
                      hide-details
                      v-if="btn_search_scoring"
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :value="selected_scoring"
                    :headers="headers_scoring"
                    :items="items_scoring"
                    :search="search_scoring"
                    @input="CheckScoring($event)"
                    item-key="scoring_id"
                    show-select
                    class="elevation-1"
                    :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'first_page',
                      lastIcon: 'last_page',
                      prevIcon: 'keyboard_arrow_left',
                      nextIcon: 'keyboard_arrow_right',
                    }"
                    dense
                    :loading="loading_scoring.list"
                    loading-text="Loading... Please wait"
                  >
                    <template v-slot:item.scoring_type="{ item }">
                      {{ item.scoring_type }} - {{ item.scoring_typedesc }}
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-icon
                        small
                        @click="ConfirmScoring('delete', item.scoring_id)"
                        :disabled="access.delete === 0 ? true : false"
                        title="delete"
                      >
                        delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </template>
              </v-tab-item>
              <v-tab-item value="tab-doccheck">
                <template>
                  <v-card-title class="ml-n4">
                    <v-btn
                      :color="
                        selected_doccheck.length === items_doccheck.length
                          ? 'fourth'
                          : ''
                      "
                      @click="SelectAllPageDocCheck"
                      class="mr-2"
                      title="select all page"
                      small
                      >{{ $vars.V("txt_select_all_page") }}
                    </v-btn>
                    <v-btn
                      color="error"
                      title="delete selected"
                      @click="ConfirmDocCheck('multidelete', '')"
                      class="mr-2"
                      v-if="selected_doccheck.length > 0"
                      :disabled="access.delete === 0 ? true : false"
                      small
                    >
                      <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
                    </v-btn>
                    <v-btn
                      @click="AddNewDocCheck"
                      class="mr-2"
                      title="Add New"
                      small
                      >Add
                    </v-btn>
                    <v-btn
                      color="primary"
                      title="search"
                      v-if="btn_search_doccheck === false"
                      @click="btn_search_doccheck = true"
                      small
                    >
                      <v-icon>{{ $vars.V("icon_search") }}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search_doccheck"
                      append-icon="search"
                      @click:append="btn_search_doccheck = false"
                      label="Search"
                      single-line
                      hide-details
                      v-if="btn_search_doccheck"
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :value="selected_doccheck"
                    :headers="headers_doccheck"
                    :items="items_doccheck"
                    :search="search_doccheck"
                    @input="CheckDocCheck($event)"
                    item-key="subclassdoccheck_id"
                    show-select
                    class="elevation-1"
                    :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'first_page',
                      lastIcon: 'last_page',
                      prevIcon: 'keyboard_arrow_left',
                      nextIcon: 'keyboard_arrow_right',
                    }"
                    dense
                    :loading="loading.doccheck"
                    loading-text="Loading... Please wait"
                  >
                    <template v-slot:item.action="{ item }">
                      <v-icon
                        small
                        @click="
                          ConfirmDocCheck('delete', item.subclassdoccheck_id)
                        "
                        :disabled="access.delete === 0 ? true : false"
                        title="delete"
                      >
                        delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </template>
              </v-tab-item>
            </v-tabs>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item value="tab-list">
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
              >{{ $vars.V("txt_select_all_page") }}
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{ $vars.V("icon_search") }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="subclass_id"
            show-select
            class="elevation-1"
            :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.subclass_classid="{ item }">
              {{ item.subclass_classid }} - {{ item.subclass_classdesc }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.subclass_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click="Confirm('delete', item.subclass_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Users -->
    <app-users
      :visible="dialog.users"
      :users_id="form.display_users"
      @close="dialog.users = false"
    ></app-users>
    <v-dialog
      v-model="dialog.objectinfo"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="1024"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('objectinfo')">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title
            >Object Info - {{ form.subclass_desc }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Confirm('objectinfo', '')"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.objectinfo"
          ></v-progress-linear>
          <template v-for="index in 20">
            <v-row :class="index > 1 ? 'mt-n1' : 'mt-3'" :key="index">
              <v-col cols="12" sm="4" md="4">
                <app-cb
                  cb_type="gentab"
                  cb_url="apiListGenTab"
                  cb_url_parameter="gentab_actived=Y"
                  :cb_title="`Object Info - ${index}`"
                  :cb_id="`subclass_fieldid_${index}`"
                  :cb_desc="`subclass_fieldname_${index}`"
                  cb_rules=""
                  cb_desc_readonly="readonly"
                  cb_items_id="gentab_id"
                  cb_items_desc="gentab_desc"
                  :cb_items_add="`${index}`"
                  :cb_value_id="subclass_fieldid_value[index]"
                  :cb_value_desc="subclass_fieldname_value[index]"
                  @clicked="GetData"
                  dense
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="mt-n3">
                <v-text-field
                  :value="subclass_fieldlabel[index]"
                  required
                  label="Label"
                  placeholder="Label"
                  :id="`subclass_fieldlabel_${index}`"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-text-field
                  :value="subclass_fielddefaultvalue[index]"
                  required
                  label="Default Value"
                  placeholder="Default Value"
                  :id="`subclass_fielddefaultvalue_${index}`"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="mt-n3">
                <input
                  type="checkbox"
                  :id="`subclass_fieldreg_${index}`"
                  :checked="subclass_fieldreg[index] == 'Y' ? true : false"
                  dense
                />
                <label class="cursor" :for="`subclass_fieldreg_${index}`">
                  Acml.
                </label>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="scoring_dialog"
      max-width="800"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="scoring_dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Scoring</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddNewScoring" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="ConfirmScoring(url_type_scoring, '')"
            title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row class="mx-auto mt-5">
            <v-col cols="12" sm="6" md="6">
              <app-cb
                cb_type="code"
                cb_url="apiListCode"
                cb_url_parameter="code_actived=Y&code_group=5CTYPE"
                cb_title="5C"
                cb_id="scoring_type"
                cb_desc="scoring_typedesc"
                cb_rules="Please fill 5C"
                cb_desc_readonly="readonly"
                cb_items_id="code_id"
                cb_items_desc="code_id"
                :cb_value_id="form.scoring_type"
                :cb_value_desc="form.scoring_typedesc"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-text-field
                v-model="form.scoring_subtype"
                required
                :rules="form.scoring_subtype_rules"
                :maxlength="maxinput.desc"
                :counter="maxinput.desc"
                label="Sub Type *"
                placeholder="Sub Type"
                id="scoring_subtype"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="10" md="10" class="mt-n3">
              <v-text-field
                v-model="form.scoring_title"
                required
                :rules="form.scoring_title_rules"
                :maxlength="maxinput.desc"
                :counter="maxinput.desc"
                label="Option *"
                placeholder="Option"
                id="scoring_title"
              >
              </v-text-field>
            </v-col>
            <!-- Score -->
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-text-field
                v-model.lazy="form.scoring_score"
                ref="scoring_score"
                label="Score"
                placeholder="0"
                background-color="numeric"
                v-money="format_subclass.score"
                id="scoring_score"
                reverse
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="doccheck_dialog"
      max-width="800"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="doccheck_dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Dokumen Cek</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="AddNewDocCheck" title="Add New"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="ConfirmDocCheck(url_type_doccheck, '')"
            title="Save"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row class="mx-auto mt-5">
            <v-col cols="12" sm="6" md="6">
              <app-cb
                cb_type="doccheck"
                cb_url="apiListDocCheck"
                cb_url_parameter="doccheck_actived=Y"
                cb_title="Kategori"
                cb_id="doccheck_id"
                cb_desc="doccheck_category"
                cb_rules="Pilih Dokumen"
                cb_desc_readonly="readonly"
                cb_items_id="doccheck_id"
                cb_items_desc="doccheck_category"
                cb_items_additional_1="doccheck_subcategory"
                cb_items_additional_2="doccheck_name"
                :cb_value_id="form.doccheck_id"
                :cb_value_desc="form.doccheck_category"
                :cb_value_subcategory="form.doccheck_subcategory"
                :cb_value_name="form.doccheck_name"
                @clicked="GetDataDocCheck"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mt-1">
              <v-text-field
                v-model="form.doccheck_subcategory"
                readonly
                label="Sub Kategori"
                placeholder="Sub Kategori"
                dense
                background-color="readonly"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="10" md="10" class="mt-1">
              <v-text-field
                v-model="form.doccheck_name"
                label="Dokumen"
                placeholder="Dokumen"
                dense
                readonly
                background-color="readonly"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mt-n1 d-flex align-center">
              <input
                type="checkbox"
                v-model="form.doccheck_compulsory"
                true-value="Y"
                false-value="N"
                id="doccheck_compulsory"
                class="mr-3"
              />
              <label class="cursor" for="doccheck_compulsory"> Wajib </label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirm_doccheck.dialog"
      max-width="490"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm_doccheck.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm_doccheck.text === 'Ok' ? false : true"
            @click="LoadingDocCheck"
            >{{ confirm_doccheck.text }}</v-btn
          >
          <v-btn color="error" @click="confirm_doccheck.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirm_scoring.dialog"
      max-width="490"
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm_scoring.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="success"
            :disabled="confirm_scoring.text === 'Ok' ? false : true"
            @click="LoadingScoring"
            >{{ confirm_scoring.text }}</v-btn
          >
          <v-btn color="error" @click="confirm_scoring.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Users -->
    <app-users
      :visible="dialog.users"
      :users_id="form.display_users"
      @close="dialog.users = false"
    ></app-users>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    url_type_scoring: "add",
    cont: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 6,
      desc: 150,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        value: "subclass_id",
      },
      { text: "Class", value: "subclass_classid" },
      { text: "Description", value: "subclass_desc" },
      { text: "Actived", value: "subclass_actived" },
      { text: "Actions", value: "action", sortable: false },
    ],
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    accessmenu: [],
    loading: {
      page: true,
      list: false,
      objectinfo: false,
    },
    btn_search: false,
    form: {
      subclass_id: "",
      subclass_desc: "",
      subclass_ujroh: 0,
      subclass_maxoutgo: 0,
      subclass_passengerf: 0,
      subclass_pmlf: 0,
      subclass_ojkratef: 0,
      subclass_voyagef: 0,
      subclass_indemnityf: 0,
      subclass_conjunctionf: 0,
      subclass_actived: true,

      subclass_id_readonly: false,

      subclass_id_rules: [(v) => !!v || "Please fill ID"],
      subclass_desc_rules: [(v) => !!v || "Please fill Description"],
      subclass_ujroh_rules: [(v) => !!v || "Please fill Ujroh"],
      subclass_maxoutgo_rules: [(v) => !!v || "Please fill Max Out Go"],
      display_users: "",
      scoring_type: "",
      scoring_typedesc: "",
      scoring_subtype: "",
      scoring_subtype_rules: [(v) => !!v || "Please fill SUb Type"],
      scoring_subtype_readonly: false,
      scoring_title: "",
      scoring_title_rules: [(v) => !!v || "Please fill Option"],
      scoring_title_readonly: false,
      doccheck_id: "",
      doccheck_category: "",
      doccheck_subcategory: "",
      doccheck_name: "",
      doccheck_compulsory: "",
      doccheck_cuser: "",
      doccheck_cdate: "",
      doccheck_luser: "",
      doccheck_ldate: "",
    },
    format_subclass: {
      ujroh: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      maxoutgo: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      score: {
        decimal: ".",
        thousands: ",",
        precision: 0,
      },
    },
    subclass_fieldid_value: {},
    subclass_fieldname_value: {},
    subclass_fieldlabel: {},
    subclass_fieldreg: {},
    subclass_fielddefaultvalue: {},
    subclass_objectinfo: [],
    loader: null,
    loading_save: false,
    confirm_scoring: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    scoring_dialog: false,
    selected_scoring: [],
    search_scoring: "",
    headers_scoring: [
      { text: "5C", value: "scoring_type" },
      { text: "Sub Type", value: "scoring_subtype" },
      { text: "Option", value: "scoring_title" },
      { text: "Score", value: "scoring_score" },
      { text: "Actions", value: "action", sortable: false },
    ],
    items_scoring: [],
    loading_scoring: {
      page: true,
      list: false,
    },
    btn_search_scoring: false,
    dialog: {
      objectinfo: false,
      users: false,
    },
    confirm_doccheck: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    doccheck_dialog: false,
    selected_doccheck: [],
    search_doccheck: "",
    headers_doccheck: [
      { text: "Sub Class", value: "subclassdoccheck_subclassid" },
      { text: "Kategori", value: "subclassdoccheck_category" },
      { text: "Sub Kategori", value: "subclassdoccheck_subcategory" },
      { text: "Dokumen", value: "subclassdoccheck_name" },
      { text: "Wajib", value: "subclassdoccheck_compulsory" },
      { text: "Actions", value: "action", sortable: false },
    ],
    items_doccheck: [],
    loading_doccheck: {
      page: true,
      list: false,
    },
    btn_search_doccheck: false,
  }),
  created() {
    this.FirstLoad();
  },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access();
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "subclass";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            if (priv[0].feedback !== "Y") {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = priv[0].feedback;
            } else {
              this.access.read = priv[0].usersmenu_read;
              this.access.add = priv[0].usersmenu_add;
              this.access.edit = priv[0].usersmenu_edit;
              this.access.delete = priv[0].usersmenu_delete;
              if (priv[0].usersmenu_read === 0) {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.NoPriviledge(modul),
                  0
                );
              }
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    SelectAllPageScoring() {
      this.selected_scoring =
        this.selected_scoring === this.items_scoring ? [] : this.items_scoring;
    },
    CheckScoring(value) {
      this.selected_scoring = value;
    },
    //Function for get all data from table SubClass with API in mod_users.go
    List() {
      this.loading.list = true;
      let formdata = {
        subclass_id: "",
        subclass_desc: "",
        order_by: "subclass_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListSubClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.list = false;
        });
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id) {
      if (flag === "write") {
        if (this.$refs.form_subclass.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.subclass_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "objectinfo") {
        this.url_type = "objectinfo";
        this.confirm.dialog = true;
        this.confirm.title = "Save Object Info";
      }
    },

    //Function to request insert data to table SubClass 'The API will validating your data, include the user who process the data' (go to APIWriteSubClass with url_type = "add")
    Add() {
      this.url_type = "add";
      this.form.subclass_id = "";
      this.form.subclass_desc = "";
      this.form.subclass_ujroh = 0;
      this.form.subclass_maxoutgo = 0;
      this.form.subclass_passengerf = 0;
      this.form.subclass_pmlf = 0;
      this.form.subclass_ojkratef = 0;
      this.form.subclass_voyagef = 0;
      this.form.subclass_indemnityf = 0;
      this.form.subclass_conjunctionf = 0;
      this.form.subclass_actived = true;
      this.form.subclass_id_readonly = false;
      this.form.subclass_classid = "";
      this.form.subclass_classdesc = "";

      this.$refs.subclass_ujroh.$el.getElementsByTagName("input")[0].value = 0;
      this.$refs.subclass_maxoutgo.$el.getElementsByTagName(
        "input"
      )[0].value = 0;

      setTimeout(function () {
        document.getElementById("subclass_id").focus();
      }, 500);
    },
    //Function to request update data to table SubClass 'The API will validating your data, include the user who process the data' (go to APIWriteSubClass with url_type = "edit")
    Edit(id) {
      this.loading.list = true;
      let formdata = {
        subclass_id: id,
        subclass_desc: "",
        order_by: "subclass_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListSubClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click();
              this.url_type = "edit";
              this.form.subclass_id = feedback[0].subclass_id;
              this.form.subclass_desc = feedback[0].subclass_desc;
              this.form.subclass_ujroh = feedback[0].subclass_ujroh;
              this.form.subclass_maxoutgo = feedback[0].subclass_maxoutgo;
              this.form.subclass_passengerf = this.$functions.YesOrNo(
                feedback[0].subclass_passengerf
              );
              this.form.subclass_pmlf = this.$functions.YesOrNo(
                feedback[0].subclass_pmlf
              );
              this.form.subclass_ojkratef = this.$functions.YesOrNo(
                feedback[0].subclass_ojkratef
              );
              this.form.subclass_voyagef = this.$functions.YesOrNo(
                feedback[0].subclass_voyagef
              );
              this.form.subclass_indemnityf = this.$functions.YesOrNo(
                feedback[0].subclass_indemnityf
              );
              this.form.subclass_conjunctionf = this.$functions.YesOrNo(
                feedback[0].subclass_conjunctionf
              );
              this.form.subclass_classid = feedback[0].subclass_classid;
              this.form.subclass_classdesc = feedback[0].subclass_classdesc;
              this.form.subclass_actived = this.$functions.TrueOrFalse(
                feedback[0].subclass_actived
              );
              this.form.subclass_cdate = feedback[0].subclass_cdate;
              this.form.subclass_cuser = feedback[0].subclass_cuser;
              this.form.subclass_ldate = feedback[0].subclass_ldate;
              this.form.subclass_luser = feedback[0].subclass_luser;

              this.$refs.subclass_ujroh.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].subclass_ujroh;
              this.$refs.subclass_maxoutgo.$el.getElementsByTagName(
                "input"
              )[0].value = feedback[0].subclass_maxoutgo;

              this.form.subclass_id_readonly = true;
              this.ListScoring(feedback[0].subclass_id);
              setTimeout(function () {
                document.getElementById("subclass_desc").focus();
              }, 500);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.list = false;
        });
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        subclass_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteSubClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.List();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteSubClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.List();
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.subclass_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete");
      } else if (this.url_type === "objectinfo") {
        this.WriteObjectInfo();
      }
    },
    Write() {
      let classid = document.getElementById("subclass_classid").value;

      let passengerf;
      let pmlf;
      let ojkratef;
      let voyagef;
      let indemnityf;
      let conjunctionf;

      if (this.form.subclass_passengerf == 1) {
        passengerf = "Y";
      } else {
        passengerf = "N";
      }

      if (this.form.subclass_pmlf == 1) {
        pmlf = "Y";
      } else {
        pmlf = "N";
      }

      if (this.form.subclass_ojkratef == 1) {
        ojkratef = "Y";
      } else {
        ojkratef = "N";
      }

      if (this.form.subclass_voyagef == 1) {
        voyagef = "Y";
      } else {
        voyagef = "N";
      }

      if (this.form.subclass_indemnityf == 1) {
        indemnityf = "Y";
      } else {
        indemnityf = "N";
      }

      if (this.form.subclass_conjunctionf == 1) {
        conjunctionf = "Y";
      } else {
        conjunctionf = "N";
      }

      let formdata = {
        url_type: this.url_type,
        subclass_id: this.form.subclass_id,
        subclass_desc: this.form.subclass_desc,
        subclass_classid: classid,
        subclass_ujroh: this.form.subclass_ujroh,
        subclass_maxoutgo: this.form.subclass_maxoutgo,
        subclass_passengerf: passengerf,
        subclass_pmlf: pmlf,
        subclass_ojkratef: ojkratef,
        subclass_voyagef: voyagef,
        subclass_indemnityf: indemnityf,
        subclass_conjunctionf: conjunctionf,
        subclass_actived: this.$functions.ActivedFlag(
          this.form.subclass_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteSubClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.subclass_cuser = feedback[0].feedback_users_id;
                this.form.subclass_cdate = feedback[0].feedback_users_date;
              }
              this.form.subclass_luser = feedback[0].feedback_users_id;
              this.form.subclass_ldate = feedback[0].feedback_users_date;
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    GetData(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        this.$set(this.subclass_fieldlabel, splits[2], splits[1]);
      }
    },
    WriteObjectInfo() {
      this.subclass_objectinfo = [];
      for (let i = 1; i <= 20; i++) {
        let id = document.getElementById("subclass_fieldid_" + i).value;
        let name = document.getElementById("subclass_fieldname_" + i).value;
        let label = document.getElementById("subclass_fieldlabel_" + i).value;
        let defaultvalue = document.getElementById(
          "subclass_fielddefaultvalue_" + i
        ).value;
        let reg = document.getElementById("subclass_fieldreg_" + i).checked;
        this.subclass_objectinfo.push({
          subclass_fieldid: id,
          subclass_fieldname: name,
          subclass_fielddefaultvalue: defaultvalue,
          subclass_fieldreg: reg.toString(),
          subclass_fieldlabel: label,
        });
      }
      let formdata = {
        url_type: "objectinfo",
        subclass_id: this.form.subclass_id,
        subclass_objectinfo: JSON.stringify(this.subclass_objectinfo),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteSubClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 0);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
        });
    },
    ObjectInfo() {
      this.dialog.objectinfo = true;
      this.loading.objectinfo = true;
      let formdata = {
        subclass_id: this.form.subclass_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListSubClassObjectInfo"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              for (let i = 1; i <= 20; i++) {
                let field_id = `subclass_fieldid_` + i;
                let field_name = `subclass_fieldname_` + i;
                let field_label = `subclass_fieldlabel_` + i;
                let field_defaultvalue = `subclass_fielddefaultvalue_` + i;
                let field_reg = `subclass_fieldreg_` + i;
                if (feedback[0][field_id] !== "") {
                  this.$set(
                    this.subclass_fieldid_value,
                    i,
                    feedback[0][field_id]
                  );
                  this.$set(
                    this.subclass_fieldname_value,
                    i,
                    feedback[0][field_name]
                  );
                  this.$set(
                    this.subclass_fieldlabel,
                    i,
                    feedback[0][field_label]
                  );
                  this.$set(
                    this.subclass_fielddefaultvalue,
                    i,
                    feedback[0][field_defaultvalue]
                  );
                  this.$set(this.subclass_fieldreg, i, feedback[0][field_reg]);
                } else {
                  this.$set(this.subclass_fieldid_value, i, "");
                  this.$set(this.subclass_fieldname_value, i, "");
                  this.$set(this.subclass_fieldlabel, i, "");
                  this.$set(this.subclass_fielddefaultvalue, i, "");
                  this.$set(this.subclass_fieldreg, i, "");
                  document.getElementById(
                    "subclass_fieldreg_" + i
                  ).checked = false;
                }
              }
            }
          }
          this.loading.objectinfo = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.objectinfo = false;
        });
    },
    ClearObjectInfo() {
      for (let i = 1; i <= 20; i++) {
        if (document.getElementById("subclass_fieldid_" + i) !== null) {
          this.$set(this.subclass_fieldid_value, i, "");
          this.$set(this.subclass_fieldname_value, i, "");
          this.$set(this.subclass_fieldlabel, i, "");
          this.$set(this.subclass_fielddefaultvalue, i, "");
          this.$set(this.subclass_fieldreg, i, "");
          document.getElementById("subclass_fieldreg_" + i).checked = false;
        }
      }
      this.subclass_fieldid_value = {};
      this.subclass_fieldname_value = {};
      this.subclass_fieldlabel = {};
      this.subclass_fieldreg = {};
      this.subclass_fielddefaultvalue = {};
      this.subclass_objectinfo = [];
    },
    ConfirmScoring(flag, id) {
      if (flag === "add") {
        this.confirm_scoring.dialog = true;
        this.confirm_scoring.title = "Save";
        this.url_type_scoring = "add";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "edit") {
        this.confirm_scoring.dialog = true;
        this.confirm_scoring.title = "Save";
        this.url_type_scoring = "edit";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "delete") {
        this.url_type_noc = "delete";
        this.confirm_scoring.dialog = true;
        this.confirm_scoring.title =
          "Delete Scoring`" +
          id +
          "` from Sub Class `" +
          this.form.subclass_desc +
          "`";
        this.scoring_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type_noc = "multidelete";
        this.confirm_scoring.dialog = true;
        this.confirm_scoring.title =
          "Delete `" + this.selected_causelossnoc.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    ListScoring(id) {
      this.loading_scoring.list = true;
      let formdata = {
        scoring_subclass: id,
        order_by: "scoring_id,scoring_score",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items_scoring = feedback;
            }
          } else {
            this.items_scoring = feedback;
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading_scoring.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading_scoring.list = false;
        });
    },
    DeleteScoring(id) {
      let formdata = {
        url_type: "delete",
        scoring_subclass: this.form.subclass_id,
        scoring_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm_scoring.dialog = false;
              this.confirm_scoring.text = "Ok";
              this.selected_scoring = [];
              this.ListScoring(this.form.subclass_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcessScoring() {
      let multiData = JSON.stringify(this.selected_scoring);
      let formdata = {
        url_type: this.url_type_scoring,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm_scoring.dialog = false;
            this.confirm_scoring.text = "Ok";
            this.selected_scoring = [];
            this.ListScoring(this.form.subclass_id);
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    LoadingScoring() {
      this.confirm_scoring.text = "Loading...";
      if (this.url_type_scoring === "add" || this.url_type_scoring === "edit") {
        this.WriteScoring();
      } else if (this.url_type_scoring === "delete") {
        this.DeleteScoring(this.subclass_id);
      } else if (this.url_type_scoring === "multidelete") {
        this.MultiProcessScoring("delete");
      }
    },
    WriteScoring() {
      let scoring_type = document.getElementById("scoring_type").value;
      let formdata = {
        url_type: this.url_type_scoring,
        scoring_subclass: this.form.subclass_id,
        scoring_type: scoring_type,
        scoring_subtype: this.form.scoring_subtype,
        scoring_title: this.form.scoring_title,
        scoring_score: this.form.scoring_score,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteScoring"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm_scoring.dialog = false;
              this.confirm_scoring.text = "Ok";
              if (this.url_type === "add") {
                this.form.scoring_cuser = feedback[0].feedback_users_id;
                this.form.scoring_cdate = feedback[0].feedback_users_date;
              }
              this.ListScoring(this.form.subclass_id);
              this.form.scoring_luser = feedback[0].feedback_users_id;
              this.form.scoring_ldate = feedback[0].feedback_users_date;
              this.url_type_scoring = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_scoring.dialog = false;
              this.confirm_scoring.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    AddNewScoring() {
      this.scoring_dialog = true;
      this.url_type_scoring = "add";
      this.form.scoring_subtype = "";
      this.form.scoring_title = "";
      this.form.scoring_score = 0;
    },
    CheckDocCheck(value) {
      this.selected_doccheck = value;
    },
    ConfirmDocCheck(flag, id) {
      if (flag === "add") {
        this.confirm_doccheck.dialog = true;
        this.confirm_doccheck.title = "Save";
        this.url_type_doccheck = "add";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "edit") {
        this.confirm_doccheck.dialog = true;
        this.confirm_doccheck.title = "Save";
        this.url_type_doccheck = "edit";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "delete") {
        this.url_type_doccheck = "delete";
        this.confirm_doccheck.dialog = true;
        this.confirm_doccheck.title =
          "Delete Dokumen`" +
          id +
          "` from Sub Class `" +
          this.form.doccheck_name +
          "`";
        this.subclassdoccheck_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type_doccheck = "multidelete";
        this.confirm_doccheck.dialog = true;
        this.confirm_doccheck.title =
          "Delete `" + this.selected_doccheck.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },
    SelectAllPageDocCheck() {
      this.selected_doccheck =
        this.selected_doccheck === this.items_doccheck
          ? []
          : this.items_doccheck;
    },
    LoadingDocCheck() {
      this.confirm_doccheck.text = "Loading...";
      if (
        this.url_type_doccheck === "add" ||
        this.url_type_doccheck === "edit"
      ) {
        this.WriteDocCheck();
      } else if (this.url_type_doccheck === "delete") {
        this.DeleteDocCheck(this.form.subclassdoccheck_id);
      } else if (this.url_type_doccheck === "multidelete") {
        this.MultiProcessDocCheck("delete");
      }
    },
    WriteDocCheck() {
      let compulsory = document.getElementById("doccheck_compulsory").checked;
      let formdata = {
        url_type: this.url_type_doccheck,
        subclassdoccheck_id: this.form.doccheck_id,
        subclassdoccheck_subclassid: this.form.subclass_id,
        subclassdoccheck_compulsory: compulsory.toString(),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteSubClassDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm_doccheck.dialog = false;
              this.confirm_doccheck.text = "Ok";
              if (this.url_type === "add") {
                this.form.doccheck_cuser = feedback[0].feedback_users_id;
                this.form.doccheck_cdate = feedback[0].feedback_users_date;
              }
              this.ListDocCheck(this.form.subclass_id);
              this.form.doccheck_luser = feedback[0].feedback_users_id;
              this.form.doccheck_ldate = feedback[0].feedback_users_date;
              this.url_type_doccheck = "edit";
              this.doccheck_dialog = false;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.confirm_doccheck.dialog = false;
              this.confirm_doccheck.text = "Ok";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    AddNewDocCheck() {
      this.doccheck_dialog = true;
      this.url_type_doccheck = "add";
      this.form.doccheck_id = "";
      this.form.doccheck_category = "";
      this.form.doccheck_subcategory = "";
      this.form.doccheck_compulsory = "N";
      this.form.doccheck_name = "";
      this.form.doccheck_cdate = "";
      this.form.doccheck_date = "";
      this.form.doccheck_cuser = "";
      this.form.doccheck_luser = "";
    },
    ListDocCheck(id) {
      this.loading.doccheck = true;
      let formdata = {
        subclassdoccheck_subclassid: id,
        order_by: "subclassdoccheck_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListSubClassDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items_doccheck = feedback;
            }
          } else {
            this.items_doccheck = feedback;
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading.doccheck = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.doccheck = false;
        });
    },
    DeleteDocCheck(id) {
      let formdata = {
        url_type: "delete",
        subclassdoccheck_subclassid: this.form.subclass_id,
        subclassdoccheck_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteSubClassDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm_doccheck.dialog = false;
              this.confirm_doccheck.text = "Ok";
              this.selected_doccheck = [];
              this.ListDocCheck(this.form.subclass_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcessDocCheck() {
      let multiData = JSON.stringify(this.selected_doccheck);
      let formdata = {
        url_type: this.url_type_doccheck,
        multi_data: multiData,
        subclassdoccheck_subclassid: this.form.subclass_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteSubClassDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm_doccheck.dialog = false;
            this.confirm_doccheck.text = "Ok";
            this.selected_doccheck = [];
            this.ListDocCheck(this.form.subclass_id);
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    GetDataDocCheck(value) {
      if (value !== undefined) {
        let splits = this.$functions.Split(value, "|");
        console.log(splits);
        this.form.doccheck_id = splits[0];
        this.form.doccheck_category = splits[1];
        this.form.doccheck_subcategory = splits[3];
        this.form.doccheck_name = splits[4];
      }
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    Dialog(flag) {
      if (flag === "OI") {
        this.ObjectInfo();
      }
    },
    Close(flag) {
      if (flag === "objectinfo") {
        this.url_type = "edit";
        this.dialog.objectinfo = false;
        this.ClearObjectInfo();
      } else if (flag === "confirm") {
        this.confirm.dialog = false;
        if (this.url_type !== "add") {
          this.url_type = "edit";
        }
      }
    },
    ShowUsers(id) {
      this.dialog.users = true;
      this.form.display_users = id;
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
  },
};
</script>
