import Vue from 'vue'
import Axios from 'axios'
import vuetify from './plugins/vuetify';
import router from './router'
import App from './App.vue'
import Functions from './functions'
import Variable from './variable'
import { store } from './store/store'

import { VueMaskDirective } from 'v-mask'

// Component
import DrawerMenu from '@/components/com_drawer_menu.vue'
import ComboBox from '@/components/com_combobox.vue'
import GeneralTable from '@/components/com_general_table.vue'
import ObjectInfo from '@/components/com_object_info.vue'
import Profile from '@/components/com_profile.vue'
import Users from '@/components/com_users.vue'
import Print from '@/components/com_document_print.vue'
import PrintDetail from '@/components/com_document_print_detail.vue'

// Component Profile
import ProfileOrganization from '@/components/com_profile_organization.vue'
import ProfilePorto from '@/components/com_profile_porto.vue'
import ProfileCapital from '@/components/com_profile_capital.vue'
import ProfileRate from '@/components/com_profilecg_rate.vue'
import ProfileFD from '@/components/com_profilecg_fd.vue'
import Attachment from '@/components/com_attachment.vue'
import Scoring from '@/components/com_scoring.vue'
import ScoringDetail from '@/components/com_scoring_detail.vue'

import Dashboard_leader from '@/components/com_dashboard_leader.vue'
import Dashboard_agency from '@/components/com_dashboard_agency.vue'
import Dashboard_bank from '@/components/com_dashboard_bank.vue'
import Dashboard_insurance from '@/components/com_dashboard_insurance.vue'

// Todo
import Todo from '@/components/com_todo.vue'

// Quotation Search
import QuotationSearch from '@/components/com_quotation_search.vue'

//Plugin
import Offline from 'v-offline'
// Format Money
import Money from 'v-money'
// Format Vuetify Money
import "./plugins/vuetify-money.js"
// WysiWyg
import Wysywig from 'vue-quill-editor'
// Masking
import VueTheMask from 'vue-the-mask'
// Vue Tour
import VueTour from 'vue-tour'
// Vue Moment
import VueMoment from 'vue-moment'
import Chat from 'vue-beautiful-chat'
import VueDropzone from 'vue2-dropzone'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

const plugins = {
  install () {
    Vue.functions = Functions
    Vue.prototype.$functions = Functions
  }
}
const apps = {
  install () {
    Vue.vars = Variable
    Vue.prototype.$vars = Variable
  }
}
Vue.prototype.$axios = Axios
Vue.use(plugins)
Vue.use(apps)
// Editor Wysywig
Vue.use(Wysywig)
// Format Money
Vue.use(Money)
// Vue Masking
Vue.use(VueTheMask)
// Vue Tour
Vue.use(VueTour)
// Vue Chat
Vue.use(Chat)
// Vue Moment
Vue.use(VueMoment)
Vue.component('app-offline',Offline)
Vue.component('app-drawer_menu',DrawerMenu)
Vue.component('app-cb',ComboBox)
Vue.component('app-gt',GeneralTable)
Vue.component('app-oi',ObjectInfo)
Vue.component('app-profile',Profile)
Vue.component('app-users',Users)
Vue.component('app-print',Print)
Vue.component('app-print-detail',PrintDetail)
Vue.component('vue-dropzone',VueDropzone)

// Use Profile
Vue.component('app-profile-organization',ProfileOrganization)
Vue.component('app-profile-porto',ProfilePorto)
Vue.component('app-profile-capital',ProfileCapital)
Vue.component('app-profilecg-rate',ProfileRate)
Vue.component('app-profilecg-fd',ProfileFD)

// Quotation Search
Vue.component('app-quotation-search',QuotationSearch)

// Use Attachment
Vue.component('app-attachment',Attachment)

// Use Todo
Vue.component('app-todo',Todo)

Vue.component('app-scoring',Scoring) 
Vue.component('app-scoring-detail',ScoringDetail) 
Vue.component('app-dashboard-leader',Dashboard_leader)
Vue.component('app-dashboard-agency',Dashboard_agency)
Vue.component('app-dashboard-bank',Dashboard_bank)
Vue.component('app-dashboard-insurance',Dashboard_insurance)


//Include CSS Custom
require('../src/assets/css/my.css')
require('../src/assets/css/pulse.css')
require('../src/assets/css/shake.css')

// CSS Vue Tour
require('vue-tour/dist/vue-tour.css')


Vue.directive('mask', VueMaskDirective);
//HIDE SHOW TAG
Vue.directive('visible', function(el, binding) {
	el.style.visibility = binding.value ? 'visible' : 'hidden';
});

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


