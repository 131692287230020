var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"border":"left","color":"secondary","dark":"","dense":"","icon":"analytics"}},[_vm._v(" Scoring ")]),_c('v-card',{staticClass:"mx-1 my-5",attrs:{"elevation":"5"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"score"},[_vm._v("Score: "+_vm._s(_vm.calculateTotalScore))]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.Confirm('write', '')}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-content-save")]),_vm._v("Save ")],1)],1),_c('v-row',{staticClass:"mx-2"},[_vm._l((_vm.scoring),function(section,sectionIndex){return _c('v-col',{key:section.subtype,attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"subtitle-1 font-weight-medium mb-2"},[_vm._v(" "+_vm._s(sectionIndex + 1)+". "+_vm._s(section.subtype)+" ")]),_vm._l((section.items),function(item){return _c('div',{key:item.ticketscoring_row,staticClass:"custom-radio mb-2",class:{
            selected:
              _vm.selectedOptions[sectionIndex] === item.ticketscoring_title,
          },on:{"click":function($event){return _vm.HandleSelect(
              sectionIndex,
              item.ticketscoring_row,
              item.ticketscoring_score,
              item.ticketscoring_title
            )}}},[_c('div',{staticClass:"d-flex align-center justify-space-between py-3 px-4"},[_c('span',[_vm._v(_vm._s(item.ticketscoring_title))]),(
                _vm.selectedOptions[sectionIndex] === item.ticketscoring_title
              )?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")]):_c('div',{staticClass:"checkbox-placeholder"})],1)])})],2)}),_c('v-col',{staticClass:"mt-n3",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$vars.V("txt_acceptance_remark"))+" *")]),_c('v-textarea',{attrs:{"outlined":""},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],2)],1),_c('v-snackbar',{staticStyle:{"z-index":"9999"},attrs:{"timeout":_vm.snackbar.timeout,"color":"fourth","rounded":"pill","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [(_vm.snackbar.color === 'error')?_c('v-btn',{attrs:{"dark":"","text":"","small":""},nativeOn:{"click":function($event){_vm.remarks.dialog = true}}},[_vm._v("Open")]):_vm._e(),_c('v-btn',{attrs:{"dark":"","text":"","small":""},nativeOn:{"click":function($event){_vm.snackbar.dialog = false}}},[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.dialog),callback:function ($$v) {_vm.$set(_vm.snackbar, "dialog", $$v)},expression:"snackbar.dialog"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('v-dialog',{attrs:{"max-width":_vm.Variable('confirm', 0),"persistent":""},model:{value:(_vm.confirm.dialog),callback:function ($$v) {_vm.$set(_vm.confirm, "dialog", $$v)},expression:"confirm.dialog"}},[_c('v-card',{attrs:{"color":_vm.Variable('confirm', 4)}},[_c('v-card-title',{class:_vm.Variable('confirm', 3)},[_vm._v(_vm._s(_vm.confirm.title)+"?")]),_c('v-card-text'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"dialog","color":_vm.Variable('confirm', 1),"disabled":_vm.confirm.text === 'Ok' ? false : true},on:{"click":_vm.Write}},[_vm._v(_vm._s(_vm.confirm.text))]),_c('v-btn',{attrs:{"color":_vm.Variable('confirm', 2)},on:{"click":function($event){_vm.confirm.dialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }