<template>
  <div>
    <app-drawer_menu drawer_menu_title="Document"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs
      background-color="primary"
      dark
      color="fourth"
      v-if="access.read === 1"
    >
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad">
        {{ $vars.V("txt_form") }}
      </v-tab>
      <v-tab href="#tab-list" @click="List">
        {{ $vars.V("txt_list") }}
      </v-tab>
      <v-tab-item value="tab-form">
        <v-form
          enctype="multipart/form-data"
          ref="form_doccheck"
          lazy-validation
          id="content_top"
        >
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{ $vars.V("icon_add") }}</v-icon
                >{{ $vars.V("txt_add") }}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{ $vars.V("icon_save") }}</v-icon
                >{{ $vars.V("txt_save") }}
              </v-btn>
            </v-col>
            <!-- ID -->
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="form.doccheck_id"
                required
                :readonly="form.doccheck_id_readonly"
                :background-color="form.doccheck_id_readonly ? 'readonly' : ''"
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID *"
                placeholder="ID"
                id="code_id"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Group -->
            <v-col cols="12" sm="3" md="4">
              <v-text-field
                v-model="form.doccheck_category"
                required
                :rules="form.doccheck_category_rules"
                label="Kategori *"
                placeholder="Contoh Syarat Khusus"
                id="doccheck_category"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="1" md="1" class="mt-n5">
              <label class="caption">Sub Category</label>
              <v-switch
                v-model="form.doccheck_subcategoryactived"
                color="primary"
                id="form.doccheck_subcategoryactived"
                :label="
                  form.doccheck_subcategoryactived !== true ? '' : 'Aktif'
                "
                @change="handleSubCategoryChange"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <v-text-field
                ref="subcategoryField"
                v-model="form.doccheck_subcategory"
                label="Sub Kategori *"
                placeholder="Sub Kategori "
                id="doccheck_subcategory"
                :readonly="!form.doccheck_subcategoryactived"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>

            <!-- Desc -->
            <v-col cols="12" sm="9" md="9">
              <v-text-field
                v-model="form.doccheck_name"
                required
                :rules="form.doccheck_name_rules"
                label="Nama Dokumen *"
                placeholder="Nama Dokumen"
                id="doccheck_name"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Desc -->
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="form.doccheck_sortno"
                label="Urutan Dokumen *"
                placeholder="Urutan Dokumen"
                id="doccheck_sortno"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Active -->
            <v-col cols="12" sm="2" md="2" class="mt-n5">
              <label class="caption">Status Aktif</label>
              <v-switch
                v-model="form.doccheck_actived"
                color="primary"
                id="form.doccheck_actived"
                :label="form.doccheck_actived !== true ? '' : 'Aktif'"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                <v-banner
                  v-model="cont"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
  Created Date 	   : {{form.doccheck_cdate}}
  Created User 	   : <span class="cursor" @click="ShowUsers(form.doccheck_cuser)">{{form.doccheck_cuser}}</span>
  Last Update Date : {{form.doccheck_ldate}}
  Last Update User : <span class="cursor" @click="ShowUsers(form.doccheck_luser)">{{form.doccheck_luser}}</span>
  </pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item value="tab-list">
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
              >{{ $vars.V("txt_select_all_page") }}
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{ $vars.V("icon_delete") }}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{ $vars.V("icon_search") }}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="code_row"
            show-select
            class="elevation-1"
            :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.doccheck_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                mouse </v-icon
              >|
              <v-icon
                small
                @click="Confirm('delete', item.doccheck_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{ $vars.V("icon_close") }}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog
      v-model="confirm.dialog"
      :max-width="Variable('confirm', 0)"
      persistent
    >
      <v-card :color="Variable('confirm', 4)">
        <v-card-title :class="Variable('confirm', 3)"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            :color="Variable('confirm', 1)"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn :color="Variable('confirm', 2)" @click="confirm.dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Users -->
    <app-users
      :visible="dialog.users"
      :users_id="form.display_users"
      @close="dialog.users = false"
    ></app-users>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 10,
      desc: 150,
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],
    search: "",
    headers: [
      { text: "KATEGORI", value: "doccheck_category" },
      {
        text: "SUB KATEGORI",
        align: "left",
        value: "doccheck_subcategory",
      },
      { text: "NAMA DOKUMEN", value: "doccheck_name" },
      { text: "URUTAN DOKUMEN", value: "doccheck_sortno" },
      { text: "AKTIF", value: "doccheck_actived" },
      { text: "AKSI", value: "action", sortable: false },
    ],
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    accessmenu: [],
    loading: {
      page: true,
      list: false,
      codegroup: false,
    },
    btn_search: false,
    form: {
      doccheck_id: "",
      doccheck_id_readonly: true,
      doccheck_category: "",
      doccheck_category_rules: [(v) => !!v || "Kategori harus diisi"],
      doccheck_subcategoryactived: false,
      doccheck_subcategory: "N/A",
      doccheck_name: "",
      doccheck_name_rules: [(v) => !!v || "Nama dokumen harus diisi"],
      doccheck_sortno: "",
      doccheck_actived: true,
      doccheck_cdate: "",
      doccheck_cuser: "",
      doccheck_ldate: "",
      doccheck_luser: "",
    },

    dialog: {
      users: false,
    },
  }),
  created() {
    this.FirstLoad();
  },

  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access();
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "code";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            if (priv[0].feedback !== "Y") {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = priv[0].feedback;
            } else {
              this.access.read = priv[0].usersmenu_read;
              this.access.add = priv[0].usersmenu_add;
              this.access.edit = priv[0].usersmenu_edit;
              this.access.delete = priv[0].usersmenu_delete;
              if (priv[0].usersmenu_read === 0) {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.NoPriviledge(modul),
                  0
                );
              }
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    //Function for get all data from table Code with API in mod_users.go
    List() {
      this.loading.list = true;
      let formdata = {
        doccheck_id: "",
        doccheck_category: "",
        order_by: "doccheck_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.list = false;
        });
    },
    handleSubCategoryChange() {
      if (this.form.doccheck_subcategoryactived) {
        this.form.doccheck_subcategory = ""; // Reset when switch is active
        // Wait for the DOM to update, then focus the input field
        this.$nextTick(() => {
          this.$refs.subcategoryField.focus();
        });
      } else {
        this.form.doccheck_subcategory = "N/A"; // Set to 'N/A' when switch is off
      }
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id, add) {
      if (flag === "write") {
        if (this.$refs.form_doccheck.validate()) {
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id;
        this.doccheck_id = id;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "deletedoccheck") {
        this.url_type = "deletedoccheck";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "` and `" + add + "`";
        this.form.code_id = id;
        this.form.code_group = add;
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletedoccheck") {
        this.url_type = "multideletedoccheck";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.codegroup.selected.length + "` data";
        setTimeout(function () {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },

    //Function to request insert data to table Code 'The API will validating your data, include the user who process the data' (go to apiWriteDocCheck with url_type = "add")
    Add() {
      this.url_type = "add";
      this.form.doccheck_id = "";
      this.form.doccheck_category = "";
      this.form.doccheck_subcategory = "N/A";
      this.form.doccheck_name = "";
      this.form.doccheck_actived = true;
      this.form.doccheck_sortno = "";
      this.form.doccheck_id_readonly = true;
      setTimeout(function () {
        document.getElementById("code_group").focus();
      }, 500);
    },
    //Function to request update data to table Code 'The API will validating your data, include the user who process the data' (go to apiWriteDocCheck with url_type = "edit")
    Edit(id) {
      this.loading.list = true;
      let formdata = {
        doccheck_id: id,
        order_by: "doccheck_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click();
              this.url_type = "edit";
              this.form.doccheck_id = feedback[0].doccheck_id;
              this.form.doccheck_category = feedback[0].doccheck_category;
              this.form.doccheck_subcategory = feedback[0].doccheck_subcategory;
              this.form.doccheck_name = feedback[0].doccheck_name;
              this.form.doccheck_sortno = feedback[0].doccheck_sortno;
              this.form.doccheck_actived = this.$functions.TrueOrFalse(
                feedback[0].doccheck_actived
              );
              this.form.doccheck_cdate = feedback[0].doccheck_cdate;
              this.form.doccheck_cuser = feedback[0].doccheck_cuser;
              this.form.doccheck_ldate = feedback[0].doccheck_ldate;
              this.form.doccheck_luser = feedback[0].doccheck_luser;
              this.form.code_id_readonly = true;
              setTimeout(function () {
                document.getElementById("doccheck_id").focus();
              }, 500);
              // this.ListCodeGroup(feedback[0].code_group);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.list = false;
        });
    },
    Delete(id) {
      let formdata = {
        url_type: "delete",
        doccheck_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.List();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcess(flag) {
      let multiData = JSON.stringify(this.selected);
      let formdata = {
        url_type: flag,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "success", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.List();
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "delete") {
        this.Delete(this.doccheck_id);
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("multidelete");
      } else if (this.url_type === "deletedoccheck") {
        this.DeleteDoccheck(this.form.doccheck_id);
      } else if (this.url_type === "multideletedoccheck") {
        this.MultiProcessCodeGroup("multidelete");
      }
    },
    Write() {
      var url_type = this.form.doccheck_id == "" ? "add" : "edit";
      let formdata = {
        url_type: url_type,
        doccheck_id: this.form.doccheck_id,
        doccheck_category: this.form.doccheck_category,
        doccheck_subcategory: this.form.doccheck_subcategory,
        doccheck_name: this.form.doccheck_name,
        doccheck_sortno: this.form.doccheck_sortno,
        doccheck_actived: this.$functions.ActivedFlag(
          this.form.doccheck_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.doccheck_cuser = feedback[0].feedback_users_id;
                this.form.doccheck_cdate = feedback[0].feedback_users_date;
                this.Add();
              }
              this.form.doccheck_luser = feedback[0].feedback_users_id;
              this.form.doccheck_ldate = feedback[0].feedback_users_date;
              // this.url_type = "edit";
              // this.ListCodeGroup(this.form.code_group);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    SearchCodeGroup() {
      this.searchcodegroup.searchbutton = true;
      setTimeout(function () {
        document.getElementById("searchcodegroup").focus();
      }, 500);
    },

    DeleteDoccheck(id, group) {
      let formdata = {
        url_type: "delete",
        code_id: id,
        code_group: group,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "success", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.ListCodeGroup(this.form.code_group);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    SelectAllCodeGroup() {
      this.codegroup.selected =
        this.codegroup.selected === this.codegroup.items
          ? []
          : this.codegroup.items;
    },

    CheckCodeGroup(value) {
      this.codegroup.selected = value;
    },
    MultiProcessCodeGroup(flag) {
      let multiData = JSON.stringify(this.codegroup.selected);
      let formdata = {
        url_type: flag,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteDocCheck"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.url_type = "edit";
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            this.ListCodeGroup(this.form.code_group);
            this.SnackBar(true, "success", feed, 0);
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // End Modul Code Group
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    ShowUsers(id) {
      this.dialog.users = true;
      this.form.display_users = id;
    },
    Variable(flag, position) {
      return this.$functions.Variable(flag, position);
    },
  },
};
</script>
