<template>
  <div>
    <app-drawer_menu drawer_menu_title="Dashboard"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-container>
      <v-timeline>
        <v-timeline-item
          color="blue lighten-1"
          fill-dot
          left
          icon="mdi-star"
          v-for="user in items.filter(
            (user) => user.dashboard_userstype === 'MKT-LEADER'
          )"
          :key="user.dashboard_users_id"
        >
          <v-card class="hover-elevation rounded-xl" elevation="6" outlined>
            <v-card-title
              class="justify-end py-2"
              style="background-color: #016fc4"
            >
              <h2 class="text-h5 mr-4 white--text font-weight-light">
                {{ user.dashboard_userstitle }}
              </h2>
            </v-card-title>
            <v-card-text class="mt-3">
              <div class="d-flex align-center" style="gap: 12px">
                <!-- Avatar -->
                <div class="avatar-leader">
                  <v-avatar size="60">
                    <v-img
                      :src="user.dashboard_usersavatarurl"
                      alt="User Avatar"
                      contain
                      class="user-leader"
                    ></v-img>
                  </v-avatar>
                </div>
                <div class="w-50">
                  <h6 class="text-h6">{{ user.dashboard_username }}</h6>
                  <span
                    class="text-truncate d-flex align-center"
                    style="gap: 8px"
                  >
                  </span>
                </div>
                <v-spacer></v-spacer>
                <span class="text-h6 font-weight-bold">{{
                  "IDR " + $functions.NewFormatNumber(user.dashboard_usersprod)
                }}</span>
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item
          color="green lighten-1"
          fill-dot
          icon="mdi-check-decagram"
          right
          v-for="user in items.filter(
            (user) => user.dashboard_userstype === 'MKT-AGENCY'
          )"
          :key="user.dashboard_users_id"
        >
          <v-card class="hover-elevation rounded-xl">
            <v-card-title
              class="justify-end py-2"
              style="background-color: #f86312"
            >
              <h2 class="text-h5 mr-4 white--text font-weight-light">
                {{ user.dashboard_userstitle }}
              </h2>
            </v-card-title>
            <v-card-text class="mt-3">
              <div class="d-flex align-center" style="gap: 12px">
                <!-- Avatar -->
                <div class="avatar-agency">
                  <v-avatar size="60">
                    <v-img
                      :src="user.dashboard_usersavatarurl"
                      alt="User Avatar"
                      contain
                      class="user-leader"
                    ></v-img>
                  </v-avatar>
                </div>
                <div class="w-50">
                  <h6 class="text-h6">{{ user.dashboard_username }}</h6>
                  <span
                    class="text-truncate d-flex align-center"
                    style="gap: 8px"
                  >
                  </span>
                </div>
                <v-spacer></v-spacer>
                <span class="text-h6 font-weight-bold">{{
                  "IDR " + $functions.NewFormatNumber(user.dashboard_usersprod)
                }}</span>
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-container>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<script>
// import VueApexCharts from "vue-apexcharts";
export default {
  name: "DashboardLeader",
  // components: {
  //   apexchart: VueApexCharts,
  // },
  data: () => ({
    loading: {
      list: false,
      page: false,
    },
    selection: 1,
    images_welcome: "../../welcome.png",
    form: {
      users_avatar_url: "",
      users_name: "",
      users_typedesc: "",
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    snackbar: {
      dialog: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "dashboardquotation",
      },
      {
        text: "Quotations",
        disabled: false,
        href: "#",
      },
    ],
    items: [],
    donutCharts: [],
    kbg: [
      {
        color: "tertiary",
        icon: "mdi-file-check-outline",
        subtitle: "Jan 10, 2014",
        title: "Close",
      },
      {
        color: "secondary",
        icon: "mdi-file-question-outline",
        subtitle: "Jan 20, 2014",
        title: "Request",
      },
      {
        color: "primary",
        icon: "mdi-file-remove-outline",
        subtitle: "Jan 20, 2014",
        title: "Reject",
      },
    ],
    series: [
      {
        name: "Reject",
        data: [53, 32, 33],
      },

      {
        name: "Request",
        data: [70, 38, 79],
      },
      {
        name: "Close",
        data: [44, 55, 41],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 200,
      },
      colors: ["#F86312", "#006FC4", "#00B500"],
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["KONTRA BANK GARANSI", "SURETY BOND", "CUSTOM BOND"],
      },
    },
  }),
  // mounted() {
  //   // Call the function to create the donut charts on component mount
  //   this.createDonutCharts();
  // },
  created() {
    this.FirstLoad();
  },
  methods: {
    FirstLoad() {
      this.UsersDetail();
      this.ListUsers();
    },
    UsersDetail() {
      let users_detail = JSON.parse(
        localStorage.getItem("local_core_users_detail")
      );
      this.form.users_avatar_url = users_detail[0].users_avatar_url;
      this.form.users_name = users_detail[0].users_name;
      this.form.users_typedesc = users_detail[0].users_typedesc;
      this.form.users_email = users_detail[0].users_email;
      this.form.users_phone_1 = users_detail[0].users_phone_1;
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    // Function to create the donut chart object
    createDonutChart(title, series, labels, colors) {
      return {
        title: title,
        series: series,
        options: {
          chart: {
            width: 380,
            type: "pie",
            events: {
              // Add event handler for when a data point (segment) is clicked
              dataPointSelection: (event, chartContext, config) => {
                // Get the selected data point's index and value
                const label = labels[config.dataPointIndex];
                const value = series[config.dataPointIndex];
                // Call a custom function to handle the click event
                this.onChartClick(title, label, value);
              },
            },
          },
          colors: colors,
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 270,
            },
          },
          dataLabels: {
            enabled: true, // Enable data labels to show on the chart
            formatter: (val) => {
              // Format to show percentage
              return val.toFixed(2) + "%";
            },
            style: {
              fontSize: "10px",
              fontWeight: "bold",
              colors: ["#000"], // Customize text color (black)
            },
          },
          fill: {
            type: "gradient",
          },
          legend: {
            position: "right", // Positioning the legend at the bottom
            horizontalAlign: "center", // Aligning legend in the center
            fontSize: "14px",
          },
          labels: labels,
          title: {
            text: title,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom", // Ensure it's bottom on smaller screens too
                },
              },
            },
          ],
        },
      };
    },
    // Custom function to handle click events on the chart
    onChartClick(chartTitle, label, value) {
      alert(`You clicked on ${label} in ${chartTitle}. Value: ${value}`);
      // You can replace this alert with any custom action you want to perform on click.
    },
    // Function to generate multiple donut charts dynamically
    createDonutCharts() {
      const chartData = [
        {
          title: "Kontra Bank Garansi",
          series: [44, 55, 41],
          labels: ["Reject", "Request", "Close"],
          colors: ["#F86312", "#006FC4", "#00B500"],
        },
        {
          title: "Surety Bond",
          series: [25, 65, 10],
          labels: ["Reject", "Request", "Close"],
          colors: ["#F86312", "#006FC4", "#00B500"],
        },
        {
          title: "Custom Bond",
          series: [60, 20, 20],
          labels: ["Reject", "Request", "Close"],
          colors: ["#F86312", "#006FC4", "#00B500"],
        },
      ];
      // Use the createDonutChart function to populate the donutCharts array
      chartData.forEach((data) => {
        const chart = this.createDonutChart(
          data.title,
          data.series,
          data.labels,
          data.colors
        );
        this.donutCharts.push(chart);
      });
    },
    ListUsers() {
      this.loading.page = true;
      let formdata = {
        users_id: "",
        users_name: "",
        users_title: "AGENCY",
        order_by: "users_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListDashboardLeader"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.page = false;
        });
    },
  },
};
</script>

<style scoped>
.v-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  background-color: white !important;
}
.v-btn {
  text-transform: none !important;
  box-shadow: none !important;
}
.text-dark-blue {
  color: #2c3e50 !important;
}
.text-green {
  color: #20c997 !important;
}
.main {
  background: #f6f6f6 !important;
}
.avatar-border {
  background-image: linear-gradient(#016fc4, #ffb74d);
  border-radius: 50%;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.avatar-leader {
  background-image: linear-gradient(#66b3ff, #d0d9e0);
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.avatar-agency {
  background-image: linear-gradient(#f8631291, #f863128f);
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.user-image {
  border: 4px solid rgb(255, 255, 255);
}

.user-leader {
  border: 2px solid rgb(255, 255, 255);
}

.text-h5 {
  font-size: 1.125rem !important;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: normal !important;
  font-family: inherit;
  text-transform: none !important;
}
.font-weight-regular {
  font-weight: 400 !important;
}
.text-h6 {
  font-size: 1.3rem !important;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: 0.0125em !important;
  font-family: inherit;
  text-transform: none !important;
}
/* Style dasar dengan shadow ringan */
.hover-elevation {
  width: 100%;
  cursor: pointer;
  transition: box-shadow 0.2s ease, transform 0.3s ease; /* Animasi smooth untuk shadow dan transform */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Shadow default */
}

/* Ubah warna box-shadow saat hover menjadi oranye */
.hover-elevation:hover {
  transform: scale(1.02); /* Sedikit memperbesar ukuran */
  box-shadow: 0px 3px 2px rgb(98 105 98) !important;
}

.left .v-timeline-item__body {
  margin-right: auto;
}

.right .v-timeline-item__body {
  margin-left: auto;
}
</style>
